<template>
	<div class="flex flex-col w-screen sm:w-auto px-4 py-6 sm:px-6 sm:py-12 gap-5">
		<!-- Таблица -->
		<TableMain />
	</div>
</template>

<script>
import TableMain from './TableMain.vue';

export default {
	name: 'Main',
	components: { TableMain }
};
</script>
