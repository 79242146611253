<template>
	<div>
		<div class="flex flex-col relative mb-16">
			<div class="absolute right-0 -top-14">
				<Button text="Экспорт в Excel" icon="exel" @click.native="downloadExcell(params)" :disabled="loadToExcell" :load="loadToExcell" />
			</div>
			<div class="h-auto rounded-tr-lg bg-white filter shadow-default">
				<div class="px-7 py-5 text-lg text-black font-bold">Для амбулаторных и стационарных условий</div>
			</div>
			<div class="t-overflow overflow-x-auto h-auto bg-white filter shadow-default">
				<table class="w-full table-fixed" :style="data.length > 0 ? 'min-height: 400px' : ''">
					<thead class="border-t border-gray-200">
						<tr>
							<th
								v-for="(item, ind) in fields_sticky"
								:key="'S' + ind"
								:class="ind === 0 ? 'first-cell' : 'second-cell'"
								rowspan="3"
								class="px-6 py-3 border-b border-r border-gray-200 text-left text-sm leading-4 font-semibold text-gray-500 tracking-wider"
							>
								<span class="relative">
									<p class="underline cursor-pointer" :class="{ 'text-blue-500 underline': filterBy === ind }" @click="switchFilterVisibility(ind)">
										{{ item.Name }}
									</p>
									<!-- Фильтр и сортировка -->
									<FilterComponent
										v-on:change="switchFilterVisibility(null)"
										:filterOpen="filterOpen"
										:id-filter="ind"
										:param="params"
										@query="getData"
										:fields="fields_sticky"
										:item="item"
									/>
								</span>
							</th>
							<th colspan="4" class="third-cell px-6 py-3 border-r border-b border-gray-200 text-left text-sm leading-4 font-semibold text-gray-500 tracking-wider">
								Показатели, характеризующие общие критерии оценки качества условий оказания услуг медицинскими организациями, в отношении которых проводится независимая оценка
							</th>
						</tr>
						<tr>
							<th
								v-for="(item, ind) in subFields"
								:key="'K' + ind"
								colspan="2"
								class="px-7 py-5 border-b border-r border-gray-200 text-left text-sm font-semibold leading-4 text-gray-500 tracking-wider"
							>
								<p>{{ item }}</p>
							</th>
						</tr>
						<tr>
							<th
								v-for="(item, ind) in fields"
								:key="'L' + ind"
								class="px-7 py-5 border-b border-r border-gray-200 text-left text-sm font-semibold leading-4 text-gray-500 tracking-wider"
							>
								<p>{{ item }}</p>
							</th>
						</tr>
					</thead>
					<tbody v-for="(item, indx) in data" :key="'A' + indx">
						<tr :class="{ 'bg-gray-30': item.id === opened, 'cursor-pointer': item.hasBranch }" @click="openRow(item)">
							<td class="px-6 py-4 font-normal text-sm whitespace-no-wrap border-b text-left border-gray-200 leading-5 text-gray-500">
								<span>
									<p :class="{ underline: item.hasBranch }">{{ item.medicalOrgName }}</p>
								</span>
							</td>
							<td class="px-6 py-4 font-normal text-sm text-left whitespace-no-wrap border-b border-gray-200 leading-5 text-gray-500">
								<span>
									<p>{{ item.protokolNumber }}</p>
								</span>
							</td>
							<td class="px-6 py-4 font-normal text-sm text-left whitespace-no-wrap border-b border-gray-200 leading-5 text-gray-500">
								<span>
									<p>{{ item.protokolDate }}</p>
								</span>
							</td>
							<td class="px-6 py-4 font-normal text-sm text-left whitespace-no-wrap border-b border-gray-200 leading-5 text-gray-500">
								<span>
									<p>{{ item.value1_1 }}</p>
								</span>
							</td>
							<td class="px-6 py-4 font-normal text-sm text-left whitespace-no-wrap border-b border-gray-200 leading-5 text-gray-500">
								<span>
									<p>{{ item.value1_2 }}</p>
								</span>
							</td>
							<td class="px-6 py-4 font-normal text-sm text-left whitespace-no-wrap border-b border-gray-200 leading-5 text-gray-500">
								<span>
									<p>{{ item.value3_1 }}</p>
								</span>
							</td>
							<td class="px-6 py-4 font-normal text-sm text-left whitespace-no-wrap border-b border-gray-200 leading-5 text-gray-500">
								<span>
									<p>{{ item.value3_2 }}</p>
								</span>
							</td>
						</tr>
						<tr v-if="opened === item.id">
							<td colspan="7" class="bg-gray-20">
								<table class="w-full table-fixed">
									<tbody v-for="(subitem, indx) in item.branches" :key="'N' + indx">
										<tr>
											<td class="px-6 py-3 font-normal text-sm whitespace-no-wrap border-b text-left border-gray-200 leading-5 text-gray-500 first-cell">
												<span>
													<div class="flex items-center">
														<div v-if="subitem.isBranch" class="default-filial-clone">Филиал</div>
														<div v-if="subitem.isClone" class="default-filial-clone ml-2">Вторичная МО</div>
													</div>
													<p>{{ subitem.medicalOrgName }}</p>
												</span>
											</td>
											<td class="px-6 py-4 font-normal text-sm text-left whitespace-no-wrap border-b border-gray-200 leading-5 text-gray-500 second-cell">
												<span>
													<p>{{ subitem.protokolNumber }}</p>
												</span>
											</td>
											<td class="px-6 py-4 font-normal text-sm text-left whitespace-no-wrap border-b border-gray-200 leading-5 text-gray-500 second-cell">
												<span>
													<p>{{ subitem.protokolDate }}</p>
												</span>
											</td>
											<td class="px-6 py-4 font-normal text-sm text-left whitespace-no-wrap border-b border-gray-200 leading-5 text-gray-500">
												<span>
													<p>{{ subitem.value1_1 }}</p>
												</span>
											</td>
											<td class="px-6 py-4 font-normal text-sm text-left whitespace-no-wrap border-b border-gray-200 leading-5 text-gray-500">
												<span>
													<p>{{ subitem.value1_2 }}</p>
												</span>
											</td>
											<td class="px-6 py-4 font-normal text-sm text-left whitespace-no-wrap border-b border-gray-200 leading-5 text-gray-500">
												<span>
													<p>{{ subitem.value3_1 }}</p>
												</span>
											</td>
											<td class="px-6 py-4 font-normal text-sm text-left whitespace-no-wrap border-b border-gray-200 leading-5 text-gray-500">
												<span>
													<p>{{ subitem.value3_2 }}</p>
												</span>
											</td>
										</tr>
									</tbody>
								</table>
							</td>
						</tr>
					</tbody>
				</table>
				<center v-if="!loadingTable && data.length === 0" class="w-full py-28 whitespace-no-wrap text-xl leading-5 text-gray-500 text-center">Данных нет...</center>
			</div>
			<!-- Навигация -->
			<div class="h-auto rounded-b-lg bg-white filter shadow-default">
				<Pagination :totalCount="totalCount" :params="params" @query="getData" />
			</div>
			<!-- Прелоудер -->
			<div v-if="loadingTable" class="absolute w-full h-full preloader rounded-lg">
				<loadingComponent class="absolute top-1/2 left-1/2" />
			</div>
		</div>
		<TableOSSecond ref="TableOSSecond" />
	</div>
</template>

<script>
// Глобальные данные стора
import { getters, methods, actions } from '@/store/store';
// Локальные данные стора
import { gettersLocal } from '../store/getters';
import action from '../../NOCCurrentYear/store/actions';
import { mutations } from '../store/mutations';
import loadingComponent from '@/components/Loading.vue';
import Pagination from '@/components/Pagination.vue';
import FilterComponent from '@/components/Filter.vue';
import Button from '@/components/Button.vue';
import TableOSSecond from './TableOSSecond.vue';

export default {
	name: 'TableOS',
	components: {
		loadingComponent,
		Pagination,
		FilterComponent,
		Button,
		TableOSSecond
	},
	computed: {
		...getters,
		...gettersLocal
	},
	data() {
		return {
			loadingTable: false,
			loadToExcell: false,
			openContentChange: false,
			rowsVisible: 10,
			totalCount: 0,
			filterOpen: null,
			filterBy: null,
			fields: ['1.1', '1.2', '3.1', '3.2'],
			subFields: ['Открытость и доступность информации об организации', 'Доступность услуг для инвалидов'],
			fields_sticky: [
				{
					Name: 'Наименование МО',
					fieldName: 'medicalOrgName',
					filterType: 2,
					filterValueType: 1,
					value1: '',
					value2: null,
					sortBy: null,
					paramsFiter: '',
					label1: 'По возрастанию (А-Я)',
					label2: 'По убыванию (Я-А)'
				},
				{
					Name: '№ протокола',
					fieldName: 'protokolNumber',
					filterType: 2,
					filterValueType: 1,
					value1: '',
					value2: null,
					sortBy: null,
					paramsFiter: '',
					label1: 'По возрастанию',
					label2: 'По убыванию'
				},
				{
					Name: 'Дата протокола',
					fieldName: 'protokolDate',
					filterType: 3,
					filterValueType: 1,
					value1: '',
					value2: null,
					sortBy: null,
					paramsFiter: '[0-9.]',
					label1: 'По возрастанию',
					label2: 'По убыванию'
				}
			],
			regionId: null,
			opened: null,
			data: [],
			params: {
				filters: [
					{
						fieldName: 'RegionId',
						filterType: 1,
						filterValueType: 1,
						value1: ''
					},
					{
						fieldName: 'isMain',
						filterType: 1,
						filterValueType: 1,
						value1: 'true',
						value2: ''
					}
				],
				sortOption: {
					fieldName: '',
					sortType: null
				},
				pageSize: 10,
				currentPage: 0
			}
		};
	},
	methods: {
		...action,
		...methods,
		...mutations,
		getRegionYearData: actions.getRegionYearData,
		openRow(item) {
			if (item.hasBranch) {
				if (this.opened === item.id) {
					this.opened = null;
				} else {
					this.opened = item.id;
				}
			}
		},
		switchFilterVisibility(key) {
			this.filterOpen = this.filterOpen === key ? null : key;
			this.filterBy = key;
		},
		getData(params, flag) {
			// если фильтруем/сортируем то возвращаем пагинацию на 1 страницу
			if (flag) {
				this.params.currentPage = 0;
			}

			this.params.filters.push({
				fieldName: 'YearStr',
				filterType: 2,
				filterValueType: 1,
				value1: this.getYear
			});
			this.loadingTable = true;

			this.getOSDecsisionAS(this.params, this.regionId).then((res) => {
				res.data.data.map((item) => {
					this.initializeItem(item);
					if (item.branches?.length > 0) item.branches.forEach((x) => this.initializeItem(x));
					return item;
				});
				this.data = res.data.data;
				this.totalCount = Math.ceil(res.data.totalRecords / this.params.pageSize);
				this.loadingTable = false;
			});
			this.params.filters.pop();
			this.$refs.TableOSSecond.getData();
		},
		initializeItem(item) {
			item.update = false;
			item.protokolDate = item.protokolDate ? this.$moment(item.protokolDate).format('YYYY-MM-DD') : '---';
		},
		downloadExcell(params) {
			this.loadToExcell = true;
			params.filters.push({
				fieldName: 'YearStr',
				filterType: 2,
				filterValueType: 1,
				value1: this.getYear
			});
			this.$http
				.post('OS/GetOSDecsisionASExcel', params, {
					responseType: 'blob',
					headers: {
						'Access-Control-Expose-Headers': 'Content-Disposition'
					}
				})
				.then((res) => {
					params.filters.pop();
					let fileNameAndType = decodeURI(`${this.regionName}_Амбулаторные и стационарные.xlsx`);

					const url = window.URL.createObjectURL(new Blob([res.data]));
					const link = document.createElement('a');
					link.href = url;
					link.setAttribute('download', fileNameAndType);
					document.body.appendChild(link);
					link.click();
					document.body.removeChild(link);
					this.loadToExcell = false;
				});
		}
	},
	mounted() {
		this.currentDate = this.$moment().format('YYYY-MM-DD');

		this.regionId = this.getRegionId();
		this.params.filters[0].value1 = this.regionId;

		if (this.getYear) {
			this.getData(null, false);
		} else {
			this.getRegionYearData(this.regionId).then((res) => {
				this.setYear(res.data.previousYear);

				this.getData(null, false);
			});
		}
	}
};
</script>

<style scoped>
.first-cell {
	width: 417px;
}

.second-cell {
	width: 187px;
}

.third-cell {
	width: 1416px;
}

.default-filial-clone {
	padding: 4px 8px;
	font-weight: 400;
	font-size: 13px;
	line-height: 20px;
	background: #a8b1ce;
	border-radius: 4px;
	width: 110px;
	color: #fff;
	text-align: center;
}

.t-overflow {
	overflow: auto;
	min-height: 300px;
	max-width: 100vw;
}

.t-overflow::-webkit-scrollbar {
	width: 11px; /* ширина всей полосы прокрутки */
}

.t-overflow::-webkit-scrollbar-track {
	background: rgba(168, 177, 206, 0.3); /* цвет зоны отслеживания */
}

.t-overflow::-webkit-scrollbar-thumb {
	background: #3377ff;
	border-radius: 10px;
}
</style>
