<template>
	<div class="flex flex-col relative mb-16">
		<div class="absolute right-0 -top-14">
			<div class="flex items-center relative">
				<!-- <div class="checkbox absolute left-0 -top-6">
					<label class="flex text-sm leading-4 font-normal text-gray-500">
						<input type="checkbox" @change="getRandom()" v-model="params.isRandom600" class="mr-2" />
						<p>Получить выборку 600 анкет</p>
					</label>
				</div> -->
				<Button text="Таблица для bus.gov.ru" class="mr-2" color="white" @click.native="downloadBusGovExcel()" :disabled="loadToExcell" :load="loadToExcell" />
				<Button text="Экспорт в Excel" icon="exel" @click.native="downloadExcell(params)" :disabled="loadToExcell" :load="loadToExcell" />
			</div>
		</div>
		<div class="t-overflow rounded-tr-lg overflow-x-auto h-auto bg-white filter shadow-default">
			<table class="table-fixed w-full" :style="data.length > 0 ? 'min-height: 350px' : ''">
				<thead>
					<tr class="border-t border-gray-200">
						<th
							v-for="(item, ind) in fields_sticky"
							:key="'J' + ind"
							:class="ind === 0 ? 'first-cell' : 'second-cell'"
							rowspan="3"
							class="px-6 py-3 border-b border-r border-gray-200 text-left text-sm leading-4 font-semibold text-gray-500 tracking-wider"
						>
							<span class="relative">
								<p :class="{ 'border-b border-gray-200 cursor-pointer': ind !== 1, 'text-blue-500 underline': filterBy === ind }" @click="ind !== 1 ? switchFilterVisibility(ind) : ''">
									{{ item.Name }}
								</p>
								<!-- Фильтр и сортировка -->
								<FilterComponent
									v-on:change="switchFilterVisibility(null)"
									:filterOpen="filterOpen"
									:id-filter="ind"
									:param="params"
									@query="getData"
									:fields="fields_sticky"
									:item="item"
								/>
							</span>
						</th>
						<th colspan="20" class="third-cell px-6 py-3 border-r border-b border-gray-200 text-center text-sm leading-4 font-semibold text-gray-500 tracking-wider">
							<p>Показатели, характеризующие общие критерии оценки качества условий оказания услуг медицинскими организациями, в отношении которых проводится независимая оценка</p>
						</th>
					</tr>
					<tr>
						<th
							v-for="(item, ind) in subFields"
							:key="'K' + ind"
							colspan="4"
							class="px-7 py-5 border-b border-r border-gray-200 text-left text-sm font-semibold leading-4 text-gray-500 tracking-wider"
						>
							<p>{{ item }}</p>
						</th>
					</tr>
					<tr>
						<th v-for="(item, ind) in fields" :key="'L' + ind" class="px-7 py-5 border-b border-r border-gray-200 text-left text-sm font-semibold leading-4 text-gray-500 tracking-wider">
							<p>{{ item }}</p>
						</th>
					</tr>
				</thead>
				<tbody v-for="(item, ind) in data" :key="'A2' + ind" class="bg-white">
					<tr :class="{ 'bg-gray-30': item.medicalId === opened, 'cursor-pointer': item.hasBranch }" @click="openRow(item)">
						<td
							:rowspan="getRowsCount(item)"
							class="px-6 py-4 text-sm text-gray-500 text-left leading-5 whitespace-no-wrap border-b border-r border-gray-200"
							:class="{ underline: item.hasBranch }"
						>
							<p>{{ item.name }}</p>
						</td>
					</tr>
					<tr v-if="item.ambulator" :class="{ 'bg-gray-30': item.medicalId === opened }">
						<td class="px-6 py-4 whitespace-no-wrap border-b border-r border-gray-200 leading-5 text-gray-500 text-left">А</td>

						<td class="px-6 py-4 text-sm text-sm whitespace-no-wrap border-b border-r border-gray-200 leading-5 text-gray-500 text-left">
							<p>{{ item.a_Result }}</p>
						</td>
						<td class="px-6 py-4 text-sm whitespace-no-wrap border-b border-r border-gray-200 leading-5 text-gray-500 text-left">
							<p>{{ item.a_Count }}</p>
						</td>

						<td class="px-6 py-4 text-sm whitespace-no-wrap border-b border-r border-gray-200 leading-5 text-gray-500 text-left">
							<p>{{ item.a_1 }}</p>
						</td>
						<td class="px-6 py-4 text-sm whitespace-no-wrap border-b border-r border-gray-200 leading-5 text-gray-500 text-left">
							<p>{{ item.a_1_1 }}</p>
						</td>
						<td class="px-6 py-4 text-sm whitespace-no-wrap border-b border-r border-gray-200 leading-5 text-gray-500 text-left">
							<p>{{ item.a_1_2 }}</p>
						</td>
						<td class="px-6 py-4 text-sm whitespace-no-wrap border-b border-r border-gray-200 leading-5 text-gray-500 text-left">
							<p>{{ item.a_1_3 }}</p>
						</td>

						<td class="px-6 py-4 text-sm whitespace-no-wrap border-b border-r border-gray-200 leading-5 text-gray-500 text-left">
							<p>{{ item.a_2 }}</p>
						</td>
						<td class="px-6 py-4 text-sm whitespace-no-wrap border-b border-r border-gray-200 leading-5 text-gray-500 text-left">
							<p>{{ item.a_2_1 }}</p>
						</td>
						<td class="px-6 py-4 text-sm whitespace-no-wrap border-b border-r border-gray-200 leading-5 text-gray-500 text-left">
							<p>{{ item.a_2_2 }}</p>
						</td>
						<td class="px-6 py-4 text-sm whitespace-no-wrap border-b border-r border-gray-200 leading-5 text-gray-500 text-left">
							<p>{{ item.a_2_3 }}</p>
						</td>

						<td class="px-6 py-4 text-sm whitespace-no-wrap border-b border-r border-gray-200 leading-5 text-gray-500 text-left">
							<p>{{ item.a_3 }}</p>
						</td>
						<td class="px-6 py-4 text-sm whitespace-no-wrap border-b border-r border-gray-200 leading-5 text-gray-500 text-left">
							<p>{{ item.a_3_1 }}</p>
						</td>
						<td class="px-6 py-4 text-sm whitespace-no-wrap border-b border-r border-gray-200 leading-5 text-gray-500 text-left">
							<p>{{ item.a_3_2 }}</p>
						</td>
						<td class="px-6 py-4 text-sm whitespace-no-wrap border-b border-r border-gray-200 leading-5 text-gray-500 text-left">
							<p>{{ item.a_3_3 }}</p>
						</td>

						<td class="px-6 py-4 text-sm whitespace-no-wrap border-b border-r border-gray-200 leading-5 text-gray-500 text-left">
							<p>{{ item.a_4 }}</p>
						</td>
						<td class="px-6 py-4 text-sm whitespace-no-wrap border-b border-r border-gray-200 leading-5 text-gray-500 text-left">
							<p>{{ item.a_4_1 }}</p>
						</td>
						<td class="px-6 py-4 text-sm whitespace-no-wrap border-b border-r border-gray-200 leading-5 text-gray-500 text-left">
							<p>{{ item.a_4_2 }}</p>
						</td>
						<td class="px-6 py-4 text-sm whitespace-no-wrap border-b border-r border-gray-200 leading-5 text-gray-500 text-left">
							<p>{{ item.a_4_3 }}</p>
						</td>

						<td class="px-6 py-4 text-sm whitespace-no-wrap border-b border-r border-gray-200 leading-5 text-gray-500 text-left">
							<p>{{ item.a_5 }}</p>
						</td>
						<td class="px-6 py-4 text-sm whitespace-no-wrap border-b border-r border-gray-200 leading-5 text-gray-500 text-left">
							<p>{{ item.a_5_1 }}</p>
						</td>
						<td class="px-6 py-4 text-sm whitespace-no-wrap border-b border-r border-gray-200 leading-5 text-gray-500 text-left">
							<p>{{ item.a_5_2 }}</p>
						</td>
						<td class="px-6 py-4 text-sm whitespace-no-wrap border-b border-r border-gray-200 leading-5 text-gray-500 text-left">
							<p>{{ item.a_5_3 }}</p>
						</td>
					</tr>
					<tr v-if="item.stacionar" :class="{ 'bg-gray-30': item.medicalId === opened }">
						<td class="px-6 py-4 whitespace-no-wrap border-b border-r border-gray-200 leading-5 text-gray-500 text-left">С</td>
						<td class="px-6 py-4 text-sm whitespace-no-wrap border-b border-r border-gray-200 leading-5 text-gray-500 text-left">
							<p>{{ item.s_Result }}</p>
						</td>
						<td class="px-6 py-4 text-sm whitespace-no-wrap border-b border-r border-gray-200 leading-5 text-gray-500 text-left">
							<p>{{ item.s_Count }}</p>
						</td>

						<td class="px-6 py-4 text-sm whitespace-no-wrap border-b border-r border-gray-200 leading-5 text-gray-500 text-left">
							<p>{{ item.s_1 }}</p>
						</td>
						<td class="px-6 py-4 text-sm whitespace-no-wrap border-b border-r border-gray-200 leading-5 text-gray-500 text-left">
							<p>{{ item.s_1_1 }}</p>
						</td>
						<td class="px-6 py-4 text-sm whitespace-no-wrap border-b border-r border-gray-200 leading-5 text-gray-500 text-left">
							<p>{{ item.s_1_2 }}</p>
						</td>
						<td class="px-6 py-4 text-sm whitespace-no-wrap border-b border-r border-gray-200 leading-5 text-gray-500 text-left">
							<p>{{ item.s_1_3 }}</p>
						</td>

						<td class="px-6 py-4 text-sm whitespace-no-wrap border-b border-r border-gray-200 leading-5 text-gray-500 text-left">
							<p>{{ item.s_2 }}</p>
						</td>
						<td class="px-6 py-4 text-sm whitespace-no-wrap border-b border-r border-gray-200 leading-5 text-gray-500 text-left">
							<p>{{ item.s_2_1 }}</p>
						</td>
						<td class="px-6 py-4 text-sm whitespace-no-wrap border-b border-r border-gray-200 leading-5 text-gray-500 text-left">
							<p>{{ item.s_2_2 }}</p>
						</td>
						<td class="px-6 py-4 text-sm whitespace-no-wrap border-b border-r border-gray-200 leading-5 text-gray-500 text-left">
							<p>{{ item.s_2_3 }}</p>
						</td>

						<td class="px-6 py-4 text-sm whitespace-no-wrap border-b border-r border-gray-200 leading-5 text-gray-500 text-left">
							<p>{{ item.s_3 }}</p>
						</td>
						<td class="px-6 py-4 text-sm whitespace-no-wrap border-b border-r border-gray-200 leading-5 text-gray-500 text-left">
							<p>{{ item.s_3_1 }}</p>
						</td>
						<td class="px-6 py-4 text-sm whitespace-no-wrap border-b border-r border-gray-200 leading-5 text-gray-500 text-left">
							<p>{{ item.s_3_2 }}</p>
						</td>
						<td class="px-6 py-4 text-sm whitespace-no-wrap border-b border-r border-gray-200 leading-5 text-gray-500 text-left">
							<p>{{ item.s_3_3 }}</p>
						</td>

						<td class="px-6 py-4 text-sm whitespace-no-wrap border-b border-r border-gray-200 leading-5 text-gray-500 text-left">
							<p>{{ item.s_4 }}</p>
						</td>
						<td class="px-6 py-4 text-sm whitespace-no-wrap border-b border-r border-gray-200 leading-5 text-gray-500 text-left">
							<p>{{ item.s_4_1 }}</p>
						</td>
						<td class="px-6 py-4 text-sm whitespace-no-wrap border-b border-r border-gray-200 leading-5 text-gray-500 text-left">
							<p>{{ item.s_4_2 }}</p>
						</td>
						<td class="px-6 py-4 text-sm whitespace-no-wrap border-b border-r border-gray-200 leading-5 text-gray-500 text-left">
							<p>{{ item.s_4_3 }}</p>
						</td>

						<td class="px-6 py-4 text-sm whitespace-no-wrap border-b border-r border-gray-200 leading-5 text-gray-500 text-left">
							<p>{{ item.s_5 }}</p>
						</td>
						<td class="px-6 py-4 text-sm whitespace-no-wrap border-b border-r border-gray-200 leading-5 text-gray-500 text-left">
							<p>{{ item.s_5_1 }}</p>
						</td>
						<td class="px-6 py-4 text-sm whitespace-no-wrap border-b border-r border-gray-200 leading-5 text-gray-500 text-left">
							<p>{{ item.s_5_2 }}</p>
						</td>
						<td class="px-6 py-4 text-sm whitespace-no-wrap border-b border-r border-gray-200 leading-5 text-gray-500 text-left">
							<p>{{ item.s_5_3 }}</p>
						</td>
					</tr>
					<tr v-if="item.healthResort" :class="{ 'bg-gray-30': item.medicalId === opened }">
						<td class="px-6 py-4 whitespace-no-wrap border-b border-r border-gray-200 leading-5 text-gray-500 text-left">К</td>
						<td class="px-6 py-4 text-sm whitespace-no-wrap border-b border-r border-gray-200 leading-5 text-gray-500 text-left">
							<p>{{ item.k_Result }}</p>
						</td>
						<td class="px-6 py-4 text-sm whitespace-no-wrap border-b border-r border-gray-200 leading-5 text-gray-500 text-left">
							<p>{{ item.k_Count }}</p>
						</td>

						<td class="px-6 py-4 text-sm whitespace-no-wrap border-b border-r border-gray-200 leading-5 text-gray-500 text-left">
							<p>{{ item.k_1 }}</p>
						</td>
						<td class="px-6 py-4 text-sm whitespace-no-wrap border-b border-r border-gray-200 leading-5 text-gray-500 text-left">
							<p>{{ item.k_1_1 }}</p>
						</td>
						<td class="px-6 py-4 text-sm whitespace-no-wrap border-b border-r border-gray-200 leading-5 text-gray-500 text-left">
							<p>{{ item.k_1_2 }}</p>
						</td>
						<td class="px-6 py-4 text-sm whitespace-no-wrap border-b border-r border-gray-200 leading-5 text-gray-500 text-left">
							<p>{{ item.k_1_3 }}</p>
						</td>

						<td class="px-6 py-4 text-sm whitespace-no-wrap border-b border-r border-gray-200 leading-5 text-gray-500 text-left">
							<p>{{ item.k_2 }}</p>
						</td>
						<td class="px-6 py-4 text-sm whitespace-no-wrap border-b border-r border-gray-200 leading-5 text-gray-500 text-left">
							<p>{{ item.k_2_1 }}</p>
						</td>
						<td class="px-6 py-4 text-sm whitespace-no-wrap border-b border-r border-gray-200 leading-5 text-gray-500 text-left">
							<p>{{ item.k_2_2 }}</p>
						</td>
						<td class="px-6 py-4 text-sm whitespace-no-wrap border-b border-r border-gray-200 leading-5 text-gray-500 text-left">
							<p>{{ item.k_2_3 }}</p>
						</td>

						<td class="px-6 py-4 text-sm whitespace-no-wrap border-b border-r border-gray-200 leading-5 text-gray-500 text-left">
							<p>{{ item.k_3 }}</p>
						</td>
						<td class="px-6 py-4 text-sm whitespace-no-wrap border-b border-r border-gray-200 leading-5 text-gray-500 text-left">
							<p>{{ item.k_3_1 }}</p>
						</td>
						<td class="px-6 py-4 text-sm whitespace-no-wrap border-b border-r border-gray-200 leading-5 text-gray-500 text-left">
							<p>{{ item.k_3_2 }}</p>
						</td>
						<td class="px-6 py-4 text-sm whitespace-no-wrap border-b border-r border-gray-200 leading-5 text-gray-500 text-left">
							<p>{{ item.k_3_3 }}</p>
						</td>

						<td class="px-6 py-4 text-sm whitespace-no-wrap border-b border-r border-gray-200 leading-5 text-gray-500 text-left">
							<p>{{ item.k_4 }}</p>
						</td>
						<td class="px-6 py-4 text-sm whitespace-no-wrap border-b border-r border-gray-200 leading-5 text-gray-500 text-left">
							<p>{{ item.k_4_1 }}</p>
						</td>
						<td class="px-6 py-4 text-sm whitespace-no-wrap border-b border-r border-gray-200 leading-5 text-gray-500 text-left">
							<p>{{ item.k_4_2 }}</p>
						</td>
						<td class="px-6 py-4 text-sm whitespace-no-wrap border-b border-r border-gray-200 leading-5 text-gray-500 text-left">
							<p>{{ item.k_4_3 }}</p>
						</td>

						<td class="px-6 py-4 text-sm whitespace-no-wrap border-b border-r border-gray-200 leading-5 text-gray-500 text-left">
							<p>{{ item.k_5 }}</p>
						</td>
						<td class="px-6 py-4 text-sm whitespace-no-wrap border-b border-r border-gray-200 leading-5 text-gray-500 text-left">
							<p>{{ item.k_5_1 }}</p>
						</td>
						<td class="px-6 py-4 text-sm whitespace-no-wrap border-b border-r border-gray-200 leading-5 text-gray-500 text-left">
							<p>{{ item.k_5_2 }}</p>
						</td>
						<td class="px-6 py-4 text-sm whitespace-no-wrap border-b border-r border-gray-200 leading-5 text-gray-500 text-left">
							<p>{{ item.k_5_3 }}</p>
						</td>
					</tr>
					<tr v-if="item.psychiatric" :class="{ 'bg-gray-30': item.medicalId === opened }">
						<td class="px-6 py-4 whitespace-no-wrap border-b border-r border-gray-200 leading-5 text-gray-500 text-left">П</td>
						<td class="px-6 py-4 text-sm whitespace-no-wrap border-b border-r border-gray-200 leading-5 text-gray-500 text-left">
							<p>{{ item.p_Result }}</p>
						</td>
						<td class="px-6 py-4 text-sm whitespace-no-wrap border-b border-r border-gray-200 leading-5 text-gray-500 text-left">
							<p>{{ item.p_Count }}</p>
						</td>

						<td class="px-6 py-4 text-sm whitespace-no-wrap border-b border-r border-gray-200 leading-5 text-gray-500 text-left">
							<p>{{ item.p_1 }}</p>
						</td>
						<td class="px-6 py-4 text-sm whitespace-no-wrap border-b border-r border-gray-200 leading-5 text-gray-500 text-left">
							<p>{{ item.p_1_1 }}</p>
						</td>
						<td class="px-6 py-4 text-sm whitespace-no-wrap border-b border-r border-gray-200 leading-5 text-gray-500 text-left">
							<p>{{ item.p_1_2 }}</p>
						</td>
						<td class="px-6 py-4 text-sm whitespace-no-wrap border-b border-r border-gray-200 leading-5 text-gray-500 text-left">
							<p>{{ item.p_1_3 }}</p>
						</td>

						<td class="px-6 py-4 text-sm whitespace-no-wrap border-b border-r border-gray-200 leading-5 text-gray-500 text-left">
							<p>{{ item.p_2 }}</p>
						</td>
						<td class="px-6 py-4 text-sm whitespace-no-wrap border-b border-r border-gray-200 leading-5 text-gray-500 text-left">
							<p>{{ item.p_2_1 }}</p>
						</td>
						<td class="px-6 py-4 text-sm whitespace-no-wrap border-b border-r border-gray-200 leading-5 text-gray-500 text-left">
							<p>{{ item.p_2_2 }}</p>
						</td>
						<td class="px-6 py-4 text-sm whitespace-no-wrap border-b border-r border-gray-200 leading-5 text-gray-500 text-left">
							<p>{{ item.p_2_3 }}</p>
						</td>

						<td class="px-6 py-4 text-sm whitespace-no-wrap border-b border-r border-gray-200 leading-5 text-gray-500 text-left">
							<p>{{ item.p_3 }}</p>
						</td>
						<td class="px-6 py-4 text-sm whitespace-no-wrap border-b border-r border-gray-200 leading-5 text-gray-500 text-left">
							<p>{{ item.p_3_1 }}</p>
						</td>
						<td class="px-6 py-4 text-sm whitespace-no-wrap border-b border-r border-gray-200 leading-5 text-gray-500 text-left">
							<p>{{ item.p_3_2 }}</p>
						</td>
						<td class="px-6 py-4 text-sm whitespace-no-wrap border-b border-r border-gray-200 leading-5 text-gray-500 text-left">
							<p>{{ item.p_3_3 }}</p>
						</td>

						<td class="px-6 py-4 text-sm whitespace-no-wrap border-b border-r border-gray-200 leading-5 text-gray-500 text-left">
							<p>{{ item.p_4 }}</p>
						</td>
						<td class="px-6 py-4 text-sm whitespace-no-wrap border-b border-r border-gray-200 leading-5 text-gray-500 text-left">
							<p>{{ item.p_4_1 }}</p>
						</td>
						<td class="px-6 py-4 text-sm whitespace-no-wrap border-b border-r border-gray-200 leading-5 text-gray-500 text-left">
							<p>{{ item.p_4_2 }}</p>
						</td>
						<td class="px-6 py-4 text-sm whitespace-no-wrap border-b border-r border-gray-200 leading-5 text-gray-500 text-left">
							<p>{{ item.p_4_3 }}</p>
						</td>

						<td class="px-6 py-4 text-sm whitespace-no-wrap border-b border-r border-gray-200 leading-5 text-gray-500 text-left">
							<p>{{ item.p_5 }}</p>
						</td>
						<td class="px-6 py-4 text-sm whitespace-no-wrap border-b border-r border-gray-200 leading-5 text-gray-500 text-left">
							<p>{{ item.p_5_1 }}</p>
						</td>
						<td class="px-6 py-4 text-sm whitespace-no-wrap border-b border-r border-gray-200 leading-5 text-gray-500 text-left">
							<p>{{ item.p_5_2 }}</p>
						</td>
						<td class="px-6 py-4 text-sm whitespace-no-wrap border-b border-r border-gray-200 leading-5 text-gray-500 text-left">
							<p>{{ item.p_5_3 }}</p>
						</td>
					</tr>
					<tr :class="{ 'bg-gray-30': item.medicalId === opened }">
						<td class="font-bold px-6 py-4 whitespace-no-wrap border-b border-r border-gray-200 leading-5 text-gray-500 text-left">Итог</td>
						<td class="px-6 py-4 text-sm font-bold whitespace-no-wrap border-b border-r border-gray-200 leading-5 text-gray-500 text-left">
							<p>{{ item.r_Result }}</p>
						</td>
						<td class="px-6 py-4 text-sm font-bold whitespace-no-wrap border-b border-r border-gray-200 leading-5 text-gray-500 text-left">
							<p>{{ item.r_Count }}</p>
						</td>

						<td class="px-6 py-4 text-sm font-bold whitespace-no-wrap border-b border-r border-gray-200 leading-5 text-gray-500 text-left">
							<p>{{ item.r_1 }}</p>
						</td>
						<td class="px-6 py-4 text-sm font-bold whitespace-no-wrap border-b border-r border-gray-200 leading-5 text-gray-500 text-left">
							<p>{{ item.r_1_1 }}</p>
						</td>
						<td class="px-6 py-4 text-sm font-bold whitespace-no-wrap border-b border-r border-gray-200 leading-5 text-gray-500 text-left">
							<p>{{ item.r_1_2 }}</p>
						</td>
						<td class="px-6 py-4 text-sm font-bold whitespace-no-wrap border-b border-r border-gray-200 leading-5 text-gray-500 text-left">
							<p>{{ item.r_1_3 }}</p>
						</td>

						<td class="px-6 py-4 text-sm font-bold whitespace-no-wrap border-b border-r border-gray-200 leading-5 text-gray-500 text-left">
							<p>{{ item.r_2 }}</p>
						</td>
						<td class="px-6 py-4 text-sm font-bold whitespace-no-wrap border-b border-r border-gray-200 leading-5 text-gray-500 text-left">
							<p>{{ item.r_2_1 }}</p>
						</td>
						<td class="px-6 py-4 text-sm font-bold whitespace-no-wrap border-b border-r border-gray-200 leading-5 text-gray-500 text-left">
							<p>{{ item.r_2_2 }}</p>
						</td>
						<td class="px-6 py-4 text-sm font-bold whitespace-no-wrap border-b border-r border-gray-200 leading-5 text-gray-500 text-left">
							<p>{{ item.r_2_3 }}</p>
						</td>

						<td class="px-6 py-4 text-sm font-bold whitespace-no-wrap border-b border-r border-gray-200 leading-5 text-gray-500 text-left">
							<p>{{ item.r_3 }}</p>
						</td>
						<td class="px-6 py-4 text-sm font-bold whitespace-no-wrap border-b border-r border-gray-200 leading-5 text-gray-500 text-left">
							<p>{{ item.r_3_1 }}</p>
						</td>
						<td class="px-6 py-4 text-sm font-bold whitespace-no-wrap border-b border-r border-gray-200 leading-5 text-gray-500 text-left">
							<p>{{ item.r_3_2 }}</p>
						</td>
						<td class="px-6 py-4 text-sm font-bold whitespace-no-wrap border-b border-r border-gray-200 leading-5 text-gray-500 text-left">
							<p>{{ item.r_3_3 }}</p>
						</td>

						<td class="px-6 py-4 text-sm font-bold whitespace-no-wrap border-b border-r border-gray-200 leading-5 text-gray-500 text-left">
							<p>{{ item.r_4 }}</p>
						</td>
						<td class="px-6 py-4 text-sm font-bold whitespace-no-wrap border-b border-r border-gray-200 leading-5 text-gray-500 text-left">
							<p>{{ item.r_4_1 }}</p>
						</td>
						<td class="px-6 py-4 text-sm font-bold whitespace-no-wrap border-b border-r border-gray-200 leading-5 text-gray-500 text-left">
							<p>{{ item.r_4_2 }}</p>
						</td>
						<td class="px-6 py-4 text-sm font-bold whitespace-no-wrap border-b border-r border-gray-200 leading-5 text-gray-500 text-left">
							<p>{{ item.r_4_3 }}</p>
						</td>

						<td class="px-6 py-4 text-sm font-bold whitespace-no-wrap border-b border-r border-gray-200 leading-5 text-gray-500 text-left">
							<p>{{ item.r_5 }}</p>
						</td>
						<td class="px-6 py-4 text-sm font-bold whitespace-no-wrap border-b border-r border-gray-200 leading-5 text-gray-500 text-left">
							<p>{{ item.r_5_1 }}</p>
						</td>
						<td class="px-6 py-4 text-sm font-bold whitespace-no-wrap border-b border-r border-gray-200 leading-5 text-gray-500 text-left">
							<p>{{ item.r_5_2 }}</p>
						</td>
						<td class="px-6 py-4 text-sm font-bold whitespace-no-wrap border-b border-r border-gray-200 leading-5 text-gray-500 text-left">
							<p>{{ item.r_5_3 }}</p>
						</td>
					</tr>
					<tr v-if="opened === item.medicalId">
						<td colspan="24" class="bg-gray-20">
							<table class="w-full table-fixed">
								<tbody v-for="(subitem, indx) in item.branches" :key="'N' + indx">
									<tr>
										<td
											:rowspan="getRowsCount(subitem)"
											class="first-cell px-6 py-4 text-sm text-gray-500 text-left leading-5 whitespace-no-wrap border-b border-r border-gray-200"
										>
											<div class="w-16 bg-textGray text-center text-xs text-white px-2 py-1 rounded mb-2.5">Филиал</div>
											{{ subitem.name }}
										</td>
										<td class="second-cell"></td>
										<td class="second-cell"></td>
										<td class="second-cell"></td>
									</tr>
									<tr v-if="subitem.ambulator">
										<td class="second-cell px-6 py-4 whitespace-no-wrap border-b border-r border-gray-200 leading-5 text-sm text-gray-500 text-left">А</td>

										<td class="second-cell px-6 py-4 text-sm text-sm whitespace-no-wrap border-b border-r border-gray-200 leading-5 text-sm text-gray-500 text-left">
											{{ subitem.a_Result }}
										</td>
										<td class="second-cell px-6 py-4 text-sm whitespace-no-wrap border-b border-r border-gray-200 leading-5 text-sm text-gray-500 text-left">
											{{ subitem.a_Count }}
										</td>

										<td class="px-6 py-4 text-sm whitespace-no-wrap border-b border-r border-gray-200 leading-5 text-gray-500 text-sm text-left">
											{{ subitem.a_1 }}
										</td>
										<td class="px-6 py-4 text-sm whitespace-no-wrap border-b border-r border-gray-200 leading-5 text-gray-500 text-sm text-left">
											{{ subitem.a_1_1 }}
										</td>
										<td class="px-6 py-4 text-sm whitespace-no-wrap border-b border-r border-gray-200 leading-5 text-gray-500 text-sm text-left">
											{{ subitem.a_1_2 }}
										</td>
										<td class="px-6 py-4 text-sm whitespace-no-wrap border-b border-r border-gray-200 leading-5 text-gray-500 text-sm text-left">
											{{ subitem.a_1_3 }}
										</td>

										<td class="px-6 py-4 text-sm whitespace-no-wrap border-b border-r border-gray-200 leading-5 text-gray-500 text-sm text-left">
											{{ subitem.a_2 }}
										</td>
										<td class="px-6 py-4 text-sm whitespace-no-wrap border-b border-r border-gray-200 leading-5 text-gray-500 text-sm text-left">
											{{ subitem.a_2_1 }}
										</td>
										<td class="px-6 py-4 text-sm whitespace-no-wrap border-b border-r border-gray-200 leading-5 text-gray-500 text-sm text-left">
											{{ subitem.a_2_2 }}
										</td>
										<td class="px-6 py-4 text-sm whitespace-no-wrap border-b border-r border-gray-200 leading-5 text-gray-500 text-sm text-left">
											{{ subitem.a_2_3 }}
										</td>

										<td class="px-6 py-4 text-sm whitespace-no-wrap border-b border-r border-gray-200 leading-5 text-gray-500 text-sm text-left">
											{{ subitem.a_3 }}
										</td>
										<td class="px-6 py-4 text-sm whitespace-no-wrap border-b border-r border-gray-200 leading-5 text-gray-500 text-sm text-left">
											{{ subitem.a_3_1 }}
										</td>
										<td class="px-6 py-4 text-sm whitespace-no-wrap border-b border-r border-gray-200 leading-5 text-gray-500 text-sm text-left">
											{{ subitem.a_3_2 }}
										</td>
										<td class="px-6 py-4 text-sm whitespace-no-wrap border-b border-r border-gray-200 leading-5 text-gray-500 text-sm text-left">
											{{ subitem.a_3_3 }}
										</td>

										<td class="px-6 py-4 text-sm whitespace-no-wrap border-b border-r border-gray-200 leading-5 text-gray-500 text-sm text-left">
											{{ subitem.a_4 }}
										</td>
										<td class="px-6 py-4 text-sm whitespace-no-wrap border-b border-r border-gray-200 leading-5 text-gray-500 text-sm text-left">
											{{ subitem.a_4_1 }}
										</td>
										<td class="px-6 py-4 text-sm whitespace-no-wrap border-b border-r border-gray-200 leading-5 text-gray-500 text-sm text-left">
											{{ subitem.a_4_2 }}
										</td>
										<td class="px-6 py-4 text-sm whitespace-no-wrap border-b border-r border-gray-200 leading-5 text-gray-500 text-sm text-left">
											{{ subitem.a_4_3 }}
										</td>

										<td class="px-6 py-4 text-sm whitespace-no-wrap border-b border-r border-gray-200 leading-5 text-gray-500 text-sm text-left">
											{{ subitem.a_5 }}
										</td>
										<td class="px-6 py-4 text-sm whitespace-no-wrap border-b border-r border-gray-200 leading-5 text-gray-500 text-sm text-left">
											{{ subitem.a_5_1 }}
										</td>
										<td class="px-6 py-4 text-sm whitespace-no-wrap border-b border-r border-gray-200 leading-5 text-gray-500 text-sm text-left">
											{{ subitem.a_5_2 }}
										</td>
										<td class="px-6 py-4 text-sm whitespace-no-wrap border-b border-r border-gray-200 leading-5 text-gray-500 text-sm text-left">
											{{ subitem.a_5_3 }}
										</td>
									</tr>
									<tr v-if="subitem.stacionar">
										<td class="second-cell px-6 py-4 whitespace-no-wrap border-b border-r border-gray-200 leading-5 text-sm text-gray-500 text-left">С</td>
										<td class="second-cell px-6 py-4 text-sm whitespace-no-wrap border-b border-r border-gray-200 leading-5 text-sm text-gray-500 text-left">
											{{ subitem.s_Result }}
										</td>
										<td class="second-cell px-6 py-4 text-sm whitespace-no-wrap border-b border-r border-gray-200 leading-5 text-sm text-gray-500 text-left">
											{{ subitem.s_Count }}
										</td>

										<td class="px-6 py-4 text-sm whitespace-no-wrap border-b border-r border-gray-200 leading-5 text-sm text-gray-500 text-left">
											{{ subitem.s_1 }}
										</td>
										<td class="px-6 py-4 text-sm whitespace-no-wrap border-b border-r border-gray-200 leading-5 text-sm text-gray-500 text-left">
											{{ subitem.s_1_1 }}
										</td>
										<td class="px-6 py-4 text-sm whitespace-no-wrap border-b border-r border-gray-200 leading-5 text-sm text-gray-500 text-left">
											{{ subitem.s_1_2 }}
										</td>
										<td class="px-6 py-4 text-sm whitespace-no-wrap border-b border-r border-gray-200 leading-5 text-sm text-gray-500 text-left">
											{{ subitem.s_1_3 }}
										</td>

										<td class="px-6 py-4 text-sm whitespace-no-wrap border-b border-r border-gray-200 leading-5 text-sm text-gray-500 text-left">
											{{ subitem.s_2 }}
										</td>
										<td class="px-6 py-4 text-sm whitespace-no-wrap border-b border-r border-gray-200 leading-5 text-sm text-gray-500 text-left">
											{{ subitem.s_2_1 }}
										</td>
										<td class="px-6 py-4 text-sm whitespace-no-wrap border-b border-r border-gray-200 leading-5 text-sm text-gray-500 text-left">
											{{ subitem.s_2_2 }}
										</td>
										<td class="px-6 py-4 text-sm whitespace-no-wrap border-b border-r border-gray-200 leading-5 text-sm text-gray-500 text-left">
											{{ subitem.s_2_3 }}
										</td>

										<td class="px-6 py-4 text-sm whitespace-no-wrap border-b border-r border-gray-200 leading-5 text-sm text-gray-500 text-left">
											{{ subitem.s_3 }}
										</td>
										<td class="px-6 py-4 text-sm whitespace-no-wrap border-b border-r border-gray-200 leading-5 text-sm text-gray-500 text-left">
											{{ subitem.s_3_1 }}
										</td>
										<td class="px-6 py-4 text-sm whitespace-no-wrap border-b border-r border-gray-200 leading-5 text-sm text-gray-500 text-left">
											{{ subitem.s_3_2 }}
										</td>
										<td class="px-6 py-4 text-sm whitespace-no-wrap border-b border-r border-gray-200 leading-5 text-sm text-gray-500 text-left">
											{{ subitem.s_3_3 }}
										</td>

										<td class="px-6 py-4 text-sm whitespace-no-wrap border-b border-r border-gray-200 leading-5 text-sm text-gray-500 text-left">
											{{ subitem.s_4 }}
										</td>
										<td class="px-6 py-4 text-sm whitespace-no-wrap border-b border-r border-gray-200 leading-5 text-sm text-gray-500 text-left">
											{{ subitem.s_4_1 }}
										</td>
										<td class="px-6 py-4 text-sm whitespace-no-wrap border-b border-r border-gray-200 leading-5 text-sm text-gray-500 text-left">
											{{ subitem.s_4_2 }}
										</td>
										<td class="px-6 py-4 text-sm whitespace-no-wrap border-b border-r border-gray-200 leading-5 text-sm text-gray-500 text-left">
											{{ subitem.s_4_3 }}
										</td>

										<td class="px-6 py-4 text-sm whitespace-no-wrap border-b border-r border-gray-200 leading-5 text-sm text-gray-500 text-left">
											{{ subitem.s_5 }}
										</td>
										<td class="px-6 py-4 text-sm whitespace-no-wrap border-b border-r border-gray-200 leading-5 text-sm text-gray-500 text-left">
											{{ subitem.s_5_1 }}
										</td>
										<td class="px-6 py-4 text-sm whitespace-no-wrap border-b border-r border-gray-200 leading-5 text-sm text-gray-500 text-left">
											{{ subitem.s_5_2 }}
										</td>
										<td class="px-6 py-4 text-sm whitespace-no-wrap border-b border-r border-gray-200 leading-5 text-sm text-gray-500 text-left">
											{{ subitem.s_5_3 }}
										</td>
									</tr>
									<tr v-if="subitem.healthResort">
										<td class="second-cell px-6 py-4 whitespace-no-wrap border-b border-r border-gray-200 leading-5 text-sm text-gray-500 text-left">К</td>
										<td class="second-cell px-6 py-4 text-sm whitespace-no-wrap border-b border-r border-gray-200 leading-5 text-sm text-gray-500 text-left">
											{{ subitem.k_Result }}
										</td>
										<td class="second-cell px-6 py-4 text-sm whitespace-no-wrap border-b border-r border-gray-200 leading-5 text-sm text-gray-500 text-left">
											{{ subitem.k_Count }}
										</td>

										<td class="px-6 py-4 text-sm whitespace-no-wrap border-b border-r border-gray-200 leading-5 text-sm text-gray-500 text-left">
											{{ subitem.k_1 }}
										</td>
										<td class="px-6 py-4 text-sm whitespace-no-wrap border-b border-r border-gray-200 leading-5 text-sm text-gray-500 text-left">
											{{ subitem.k_1_1 }}
										</td>
										<td class="px-6 py-4 text-sm whitespace-no-wrap border-b border-r border-gray-200 leading-5 text-sm text-gray-500 text-left">
											{{ subitem.k_1_2 }}
										</td>
										<td class="px-6 py-4 text-sm whitespace-no-wrap border-b border-r border-gray-200 leading-5 text-sm text-gray-500 text-left">
											{{ subitem.k_1_3 }}
										</td>

										<td class="px-6 py-4 text-sm whitespace-no-wrap border-b border-r border-gray-200 leading-5 text-sm text-gray-500 text-left">
											{{ subitem.k_2 }}
										</td>
										<td class="px-6 py-4 text-sm whitespace-no-wrap border-b border-r border-gray-200 leading-5 text-sm text-gray-500 text-left">
											{{ subitem.k_2_1 }}
										</td>
										<td class="px-6 py-4 text-sm whitespace-no-wrap border-b border-r border-gray-200 leading-5 text-sm text-gray-500 text-left">
											{{ subitem.k_2_2 }}
										</td>
										<td class="px-6 py-4 text-sm whitespace-no-wrap border-b border-r border-gray-200 leading-5 text-sm text-gray-500 text-left">
											{{ subitem.k_2_3 }}
										</td>

										<td class="px-6 py-4 text-sm whitespace-no-wrap border-b border-r border-gray-200 leading-5 text-sm text-gray-500 text-left">
											{{ subitem.k_3 }}
										</td>
										<td class="px-6 py-4 text-sm whitespace-no-wrap border-b border-r border-gray-200 leading-5 text-sm text-gray-500 text-left">
											{{ subitem.k_3_1 }}
										</td>
										<td class="px-6 py-4 text-sm whitespace-no-wrap border-b border-r border-gray-200 leading-5 text-sm text-gray-500 text-left">
											{{ subitem.k_3_2 }}
										</td>
										<td class="px-6 py-4 text-sm whitespace-no-wrap border-b border-r border-gray-200 leading-5 text-sm text-gray-500 text-left">
											{{ subitem.k_3_3 }}
										</td>

										<td class="px-6 py-4 text-sm whitespace-no-wrap border-b border-r border-gray-200 leading-5 text-sm text-gray-500 text-left">
											{{ subitem.k_4 }}
										</td>
										<td class="px-6 py-4 text-sm whitespace-no-wrap border-b border-r border-gray-200 leading-5 text-sm text-gray-500 text-left">
											{{ subitem.k_4_1 }}
										</td>
										<td class="px-6 py-4 text-sm whitespace-no-wrap border-b border-r border-gray-200 leading-5 text-sm text-gray-500 text-left">
											{{ subitem.k_4_2 }}
										</td>
										<td class="px-6 py-4 text-sm whitespace-no-wrap border-b border-r border-gray-200 leading-5 text-sm text-gray-500 text-left">
											{{ subitem.k_4_3 }}
										</td>

										<td class="px-6 py-4 text-sm whitespace-no-wrap border-b border-r border-gray-200 leading-5 text-sm text-gray-500 text-left">
											{{ subitem.k_5 }}
										</td>
										<td class="px-6 py-4 text-sm whitespace-no-wrap border-b border-r border-gray-200 leading-5 text-sm text-gray-500 text-left">
											{{ subitem.k_5_1 }}
										</td>
										<td class="px-6 py-4 text-sm whitespace-no-wrap border-b border-r border-gray-200 leading-5 text-sm text-gray-500 text-left">
											{{ subitem.k_5_2 }}
										</td>
										<td class="px-6 py-4 text-sm whitespace-no-wrap border-b border-r border-gray-200 leading-5 text-sm text-gray-500 text-left">
											{{ subitem.k_5_3 }}
										</td>
									</tr>
									<tr v-if="subitem.psychiatric">
										<td class="second-cell px-6 py-4 whitespace-no-wrap border-b border-r border-gray-200 leading-5 text-sm text-gray-500 text-left">П</td>
										<td class="second-cell px-6 py-4 text-sm whitespace-no-wrap border-b border-r border-gray-200 leading-5 text-sm text-gray-500 text-left">
											{{ subitem.p_Result }}
										</td>
										<td class="second-cell px-6 py-4 text-sm whitespace-no-wrap border-b border-r border-gray-200 leading-5 text-sm text-gray-500 text-left">
											{{ subitem.p_Count }}
										</td>

										<td class="px-6 py-4 text-sm whitespace-no-wrap border-b border-r border-gray-200 leading-5 text-sm text-gray-500 text-left">
											{{ subitem.p_1 }}
										</td>
										<td class="px-6 py-4 text-sm whitespace-no-wrap border-b border-r border-gray-200 leading-5 text-sm text-gray-500 text-left">
											{{ subitem.p_1_1 }}
										</td>
										<td class="px-6 py-4 text-sm whitespace-no-wrap border-b border-r border-gray-200 leading-5 text-sm text-gray-500 text-left">
											{{ subitem.p_1_2 }}
										</td>
										<td class="px-6 py-4 text-sm whitespace-no-wrap border-b border-r border-gray-200 leading-5 text-sm text-gray-500 text-left">
											{{ subitem.p_1_3 }}
										</td>

										<td class="px-6 py-4 text-sm whitespace-no-wrap border-b border-r border-gray-200 leading-5 text-sm text-gray-500 text-left">
											{{ subitem.p_2 }}
										</td>
										<td class="px-6 py-4 text-sm whitespace-no-wrap border-b border-r border-gray-200 leading-5 text-sm text-gray-500 text-left">
											{{ subitem.p_2_1 }}
										</td>
										<td class="px-6 py-4 text-sm whitespace-no-wrap border-b border-r border-gray-200 leading-5 text-sm text-gray-500 text-left">
											{{ subitem.p_2_2 }}
										</td>
										<td class="px-6 py-4 text-sm whitespace-no-wrap border-b border-r border-gray-200 leading-5 text-sm text-gray-500 text-left">
											{{ subitem.p_2_3 }}
										</td>

										<td class="px-6 py-4 text-sm whitespace-no-wrap border-b border-r border-gray-200 leading-5 text-sm text-gray-500 text-left">
											{{ subitem.p_3 }}
										</td>
										<td class="px-6 py-4 text-sm whitespace-no-wrap border-b border-r border-gray-200 leading-5 text-sm text-gray-500 text-left">
											{{ subitem.p_3_1 }}
										</td>
										<td class="px-6 py-4 text-sm whitespace-no-wrap border-b border-r border-gray-200 leading-5 text-sm text-gray-500 text-left">
											{{ subitem.p_3_2 }}
										</td>
										<td class="px-6 py-4 text-sm whitespace-no-wrap border-b border-r border-gray-200 leading-5 text-sm text-gray-500 text-left">
											{{ subitem.p_3_3 }}
										</td>

										<td class="px-6 py-4 text-sm whitespace-no-wrap border-b border-r border-gray-200 leading-5 text-sm text-gray-500 text-left">
											{{ subitem.p_4 }}
										</td>
										<td class="px-6 py-4 text-sm whitespace-no-wrap border-b border-r border-gray-200 leading-5 text-sm text-gray-500 text-left">
											{{ subitem.p_4_1 }}
										</td>
										<td class="px-6 py-4 text-sm whitespace-no-wrap border-b border-r border-gray-200 leading-5 text-sm text-gray-500 text-left">
											{{ subitem.p_4_2 }}
										</td>
										<td class="px-6 py-4 text-sm whitespace-no-wrap border-b border-r border-gray-200 leading-5 text-sm text-gray-500 text-left">
											{{ subitem.p_4_3 }}
										</td>

										<td class="px-6 py-4 text-sm whitespace-no-wrap border-b border-r border-gray-200 leading-5 text-sm text-gray-500 text-left">
											{{ subitem.p_5 }}
										</td>
										<td class="px-6 py-4 text-sm whitespace-no-wrap border-b border-r border-gray-200 leading-5 text-sm text-gray-500 text-left">
											{{ subitem.p_5_1 }}
										</td>
										<td class="px-6 py-4 text-sm whitespace-no-wrap border-b border-r border-gray-200 leading-5 text-sm text-gray-500 text-left">
											{{ subitem.p_5_2 }}
										</td>
										<td class="px-6 py-4 text-sm whitespace-no-wrap border-b border-r border-gray-200 leading-5 text-sm text-gray-500 text-left">
											{{ subitem.p_5_3 }}
										</td>
									</tr>
									<tr>
										<td class="second-cell font-bold px-6 py-4 whitespace-no-wrap border-b border-r border-gray-200 leading-5 text-sm text-gray-500 text-left">Итог</td>
										<td class="second-cell px-6 py-4 text-sm font-bold whitespace-no-wrap border-b border-r border-gray-200 leading-5 text-sm text-gray-500 text-left">
											{{ subitem.r_Result }}
										</td>
										<td class="second-cell px-6 py-4 text-sm font-bold whitespace-no-wrap border-b border-r border-gray-200 leading-5 text-sm text-gray-500 text-left">
											{{ subitem.r_Count }}
										</td>

										<td class="px-6 py-4 text-sm font-bold whitespace-no-wrap border-b border-r border-gray-200 leading-5 text-sm text-gray-500 text-left">
											{{ subitem.r_1 }}
										</td>
										<td class="px-6 py-4 text-sm font-bold whitespace-no-wrap border-b border-r border-gray-200 leading-5 text-sm text-gray-500 text-left">
											{{ subitem.r_1_1 }}
										</td>
										<td class="px-6 py-4 text-sm font-bold whitespace-no-wrap border-b border-r border-gray-200 leading-5 text-sm text-gray-500 text-left">
											{{ subitem.r_1_2 }}
										</td>
										<td class="px-6 py-4 text-sm font-bold whitespace-no-wrap border-b border-r border-gray-200 leading-5 text-sm text-gray-500 text-left">
											{{ subitem.r_1_3 }}
										</td>

										<td class="px-6 py-4 text-sm font-bold whitespace-no-wrap border-b border-r border-gray-200 leading-5 text-sm text-gray-500 text-left">
											{{ subitem.r_2 }}
										</td>
										<td class="px-6 py-4 text-sm font-bold whitespace-no-wrap border-b border-r border-gray-200 leading-5 text-sm text-gray-500 text-left">
											{{ subitem.r_2_1 }}
										</td>
										<td class="px-6 py-4 text-sm font-bold whitespace-no-wrap border-b border-r border-gray-200 leading-5 text-sm text-gray-500 text-left">
											{{ subitem.r_2_2 }}
										</td>
										<td class="px-6 py-4 text-sm font-bold whitespace-no-wrap border-b border-r border-gray-200 leading-5 text-sm text-gray-500 text-left">
											{{ subitem.r_2_3 }}
										</td>

										<td class="px-6 py-4 text-sm font-bold whitespace-no-wrap border-b border-r border-gray-200 leading-5 text-sm text-gray-500 text-left">
											{{ subitem.r_3 }}
										</td>
										<td class="px-6 py-4 text-sm font-bold whitespace-no-wrap border-b border-r border-gray-200 leading-5 text-sm text-gray-500 text-left">
											{{ subitem.r_3_1 }}
										</td>
										<td class="px-6 py-4 text-sm font-bold whitespace-no-wrap border-b border-r border-gray-200 leading-5 text-sm text-gray-500 text-left">
											{{ subitem.r_3_2 }}
										</td>
										<td class="px-6 py-4 text-sm font-bold whitespace-no-wrap border-b border-r border-gray-200 leading-5 text-sm text-gray-500 text-left">
											{{ subitem.r_3_3 }}
										</td>

										<td class="px-6 py-4 text-sm font-bold whitespace-no-wrap border-b border-r border-gray-200 leading-5 text-sm text-gray-500 text-left">
											{{ subitem.r_4 }}
										</td>
										<td class="px-6 py-4 text-sm font-bold whitespace-no-wrap border-b border-r border-gray-200 leading-5 text-sm text-gray-500 text-left">
											{{ subitem.r_4_1 }}
										</td>
										<td class="px-6 py-4 text-sm font-bold whitespace-no-wrap border-b border-r border-gray-200 leading-5 text-sm text-gray-500 text-left">
											{{ subitem.r_4_2 }}
										</td>
										<td class="px-6 py-4 text-sm font-bold whitespace-no-wrap border-b border-r border-gray-200 leading-5 text-sm text-gray-500 text-left">
											{{ subitem.r_4_3 }}
										</td>

										<td class="px-6 py-4 text-sm font-bold whitespace-no-wrap border-b border-r border-gray-200 leading-5 text-sm text-gray-500 text-left">
											{{ subitem.r_5 }}
										</td>
										<td class="px-6 py-4 text-sm font-bold whitespace-no-wrap border-b border-r border-gray-200 leading-5 text-sm text-gray-500 text-left">
											{{ subitem.r_5_1 }}
										</td>
										<td class="px-6 py-4 text-sm font-bold whitespace-no-wrap border-b border-r border-gray-200 leading-5 text-sm text-gray-500 text-left">
											{{ subitem.r_5_2 }}
										</td>
										<td class="px-6 py-4 text-sm font-bold whitespace-no-wrap border-b border-r border-gray-200 leading-5 text-sm text-gray-500 text-left">
											{{ subitem.r_5_3 }}
										</td>
									</tr>
								</tbody>
							</table>
						</td>
					</tr>
				</tbody>
			</table>
			<center v-if="!loadingTable && data.length === 0" class="w-full py-28 whitespace-no-wrap text-xl leading-5 text-gray-500 text-center">Данных нет...</center>
		</div>
		<!-- Навигация -->
		<div class="h-auto rounded-b-lg bg-white filter shadow-default">
			<Pagination :totalCount="totalCount" :params="params" @query="getData" />
		</div>
		<!-- Прелоудер -->
		<div v-if="loadingTable" class="absolute w-full h-full preloader rounded-lg">
			<loadingComponent class="absolute top-1/2 left-1/2" />
		</div>
	</div>
</template>

<script>
// Глобальные данные стора
import { getters, actions, methods } from '@/store/store';
import { mutations } from '../store/mutations';
// Локальные данные стора
import { gettersLocal } from '../store/getters';
import actionsLocal from '../../NOCCurrentYear/store/actions';
import loadingComponent from '@/components/Loading.vue';
import Pagination from '@/components/Pagination.vue';
import FilterComponent from '@/components/Filter.vue';
import Button from '@/components/Button.vue';

export default {
	name: 'TableNOK',
	components: {
		loadingComponent,
		Pagination,
		FilterComponent,
		Button
	},
	computed: {
		...getters,
		...gettersLocal
	},
	data() {
		return {
			selectedTerm: null,
			selectedType: null,
			medicalTerms: [
				{ name: 'Амбулаторные', label: 'Амбулаторные' },
				{ name: 'Стационарные', label: 'Стационарные' },
				{ name: 'Санаторно-курортные', label: 'Санаторно-курортные' },
				{ name: 'Психиатрические больницы', label: 'Психиатрические больницы' }
			],
			questionaryTypes: [
				{ name: 'Все', label: 'Все' },
				{ name: 'Интерактивные', label: 'Интерактивные' },
				{ name: 'Бумажные', label: 'Бумажные' }
			],
			loadingTable: false,
			loadToExcell: false,
			circumference: 30 * 2 * Math.PI,
			rowOpened: {
				key: null,
				type: null
			},
			openContentChange: false,
			rowsVisible: 10,
			totalCount: 0,
			filterOpen: null,
			filterBy: null,
			opened: null,
			fields: ['1', '1.1', '1.2', '1.3', '2', '2.1', '2.2', '2.3', '3', '3.1', '3.2', '3.3', '4', '4.1', '4.2', '4.3', '5', '5.1', '5.2', '5.3'],
			subFields: [
				'Открытость и доступность информации об организации',
				'Комфортность условий предоставления услуг, включая время ожидания предоставления медицинской услуги',
				'Доступность услуг для инвалидов',
				'Доброжелательность, вежливость работников медицинской организации',
				'Удовлетворенность условиями оказания услуг'
			],
			fields_sticky: [
				{
					Name: 'Наименование МО',
					fieldName: 'Name',
					filterType: 2,
					filterValueType: 1,
					value1: '',
					value2: null,
					sortBy: null,
					paramsFiter: '',
					label1: 'По возрастанию (А-Я)',
					label2: 'По убыванию (Я-А)'
				},
				{
					Name: 'Условие оказания',
					fieldName: '',
					filterType: 2,
					filterValueType: 1,
					value1: '',
					value2: null,
					sortBy: null,
					paramsFiter: '[0-9.]',
					label1: 'По возрастанию',
					label2: 'По убыванию'
				},
				{
					Name: 'Итоговый результат по условиям оказания',
					fieldName: 'r_Result',
					filterType: 1,
					filterValueType: 2,
					value1: '',
					value2: null,
					sortBy: null,
					paramsFiter: '[0-9.]',
					label1: 'По возрастанию',
					label2: 'По убыванию'
				},
				{
					Name: 'Количество анкет',
					fieldName: 'r_Count',
					filterType: 1,
					filterValueType: 2,
					value1: '',
					value2: null,
					sortBy: null,
					paramsFiter: '[0-9.]',
					label1: 'По возрастанию',
					label2: 'По убыванию'
				}
			],
			region: null,
			data: [],
			params: {
				filters: [
					{
						fieldName: 'RegionId',
						filterType: 1,
						filterValueType: 1,
						value1: ''
					},
					{
						fieldName: 'isMain',
						filterType: 1,
						filterValueType: 1,
						value1: 'true',
						value2: ''
					}
				],
				sortOption: {
					fieldName: '',
					sortType: null
				},
				isRandom600: false,
				pageSize: 10,
				currentPage: 0
			}
		};
	},
	methods: {
		...actions,
		...actionsLocal,
		...methods,
		...mutations,
		getRandom() {
			this.getData(null, true);
		},
		openRow(item) {
			if (item.hasBranch) {
				if (this.opened === item.medicalId) {
					this.opened = null;
				} else {
					this.opened = item.medicalId;
				}
			}
		},
		getRowsCount(item) {
			let count = 2;
			if (item.ambulator) count += 1;
			if (item.stacionar) count += 1;
			if (item.healthResort) count += 1;
			if (item.psychiatric) count += 1;
			return count;
		},
		switchFilterVisibility(key) {
			this.filterOpen = this.filterOpen === key ? null : key;
			this.filterBy = key;
		},
		getData(params, flag) {
			// если фильтруем/сортируем то возвращаем пагинацию на 1 страницу
			if (flag) {
				this.params.currentPage = 0;
			}

			this.params.filters.push({
				fieldName: 'YearStr',
				filterType: 2,
				filterValueType: 1,
				value1: this.getYear
			});
			this.loadingTable = true;
			this.getResultForYearByRegion(this.params).then((res) => {
				this.data = res.data.data;
				this.totalCount = Math.ceil(res.data.totalRecords / this.params.pageSize);
				this.loadingTable = false;
			});
			this.params.filters.pop();
		},
		downloadExcell(params) {
			this.loadToExcell = true;
			params.filters.push({
				fieldName: 'YearStr',
				filterType: 2,
				filterValueType: 1,
				value1: this.getYear
			});
			this.$http
				.post(`MedicalOrgs/GetResultForYearExcel?isRandom600=${params.isRandom600}`, params, {
					responseType: 'blob',
					headers: {
						'Access-Control-Expose-Headers': 'Content-Disposition'
					}
				})
				.then((res) => {
					params.filters.pop();
					let fileNameAndType = decodeURI(`${this.region.name}_Результаты НОК текущего год.xlsx`);

					const url = window.URL.createObjectURL(new Blob([res.data]));
					const link = document.createElement('a');
					link.href = url;
					link.setAttribute('download', fileNameAndType);
					document.body.appendChild(link);
					link.click();
					document.body.removeChild(link);
					this.loadToExcell = false;
				});
		},
		downloadBusGovExcel() {
			this.loadToExcell = true;

			this.$http
				.post(
					`MedicalOrgs/GetBusGovRuExcel?RegionId=${this.region.id}&Year=${this.getYear}`,
					{},
					{
						responseType: 'blob',
						headers: {
							'Access-Control-Expose-Headers': 'Content-Disposition'
						}
					}
				)
				.then((res) => {
					let fileNameAndType = decodeURI(`${this.region.name}_${this.getYear}_Таблица для busgov.xlsx`);

					const url = window.URL.createObjectURL(new Blob([res.data]));
					const link = document.createElement('a');
					link.href = url;
					link.setAttribute('download', fileNameAndType);
					document.body.appendChild(link);
					link.click();
					document.body.removeChild(link);
					this.loadToExcell = false;
				});
		}
	},
	mounted() {
		this.getCurrentRegion().then((result) => {
			this.region = result;
		});

		let regionId = this.getRegionId();
		this.params.filters[0].value1 = regionId;

		if (this.getYear) {
			this.getData(null, false);
		} else {
			this.getRegionYearData(this.regionId).then((res) => {
				this.setYear(res.data.previousYear);

				this.getData(null, false);
			});
		}
	}
};
</script>

<style scoped>
.first-cell {
	width: 417px;
}

.second-cell {
	width: 200px;
}

.third-cell {
	width: 2625px;
}

.t-overflow {
	overflow: auto;
	min-height: 300px;
	max-width: 100vw;
}

.t-overflow::-webkit-scrollbar {
	width: 11px; /* ширина всей полосы прокрутки */
}

.t-overflow::-webkit-scrollbar-track {
	background: rgba(168, 177, 206, 0.3); /* цвет зоны отслеживания */
}

.t-overflow::-webkit-scrollbar-thumb {
	background: #3377ff;
	border-radius: 10px;
}
</style>
