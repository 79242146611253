<template>
	<div class="flex flex-col relative mb-16">
		<div class="absolute right-0 -top-14">
			<div class="flex items-center">
				<Button
					text="Скачать"
					icon="upload"
					class="mr-2"
					color="white"
					spinnerColor="rgba(59, 130, 246, 255)"
					@click.native="upLoadSaveExcel(params)"
					:disabled="loadToExcell"
					:load="loadToExcell"
				/>
				<Button text="Экспорт в Excel" icon="exel" @click.native="downloadExcell(params)" :disabled="loadToExcell" :load="loadToExcell" />
			</div>
		</div>
		<div class="h-auto rounded-tr-lg bg-white filter shadow-default">
			<div class="px-7 py-5 flex items-center">
				<span class="mr-3 text-sm text-gray-500 tracking-wider leading-4 font-semibold"> Мед. условия </span>
				<div class="mr-5 w-72">
					<v-select :options="medArr" v-model="params.filters[1].fieldName" :reduce="(option) => option.value" @input="getData(null, true)"></v-select>
				</div>
				<span class="mr-3 text-sm text-gray-500 tracking-wider leading-4 font-semibold"> Тип анкеты </span>
				<div class="w-56">
					<v-select :options="anketType" v-model="params.filters[2].value1" :reduce="(option) => option.value" @input="getData(null, true)"></v-select>
				</div>
			</div>
		</div>
		<div class="t-overflow overflow-x-auto h-auto bg-white filter shadow-default">
			<table class="table-fixed w-full">
				<thead>
					<tr class="border-t border-gray-200">
						<th
							v-for="(item, ind) in fields_sticky"
							:key="'J' + ind"
							:class="ind === 0 ? 'first-cell' : 'second-cell'"
							rowspan="3"
							class="px-6 py-3 border-b border-r border-gray-200 text-left text-sm leading-4 font-semibold text-gray-500 tracking-wider"
						>
							<span class="relative">
								<p class="underline cursor-pointer" :class="{ 'text-blue-500 underline': filterBy === ind }" @click="switchFilterVisibility(ind)">
									{{ item.Name }}
								</p>
								<!-- Фильтр и сортировка -->
								<FilterComponent
									v-on:change="switchFilterVisibility(null)"
									:filterOpen="filterOpen"
									:id-filter="ind"
									:param="params"
									@query="getData"
									:fields="fields_sticky"
									:item="item"
								/>
							</span>
						</th>
						<th colspan="13" class="third-cell px-6 py-3 border-r border-b border-gray-200 text-center text-sm leading-4 font-semibold text-gray-500 tracking-wider">
							<p>Показатели, характеризующие общие критерии оценки качества условий оказания услуг медицинскими организациями, в отношении которых проводится независимая оценка</p>
						</th>
					</tr>
					<tr>
						<th
							v-for="(item, ind) in subFields"
							:key="'K' + ind"
							:colspan="ind === 0 ? 1 : 3"
							class="px-7 py-5 border-b border-r border-gray-200 text-left text-sm font-semibold leading-4 text-gray-500 tracking-wider"
						>
							<p>{{ item }}</p>
						</th>
					</tr>
					<tr>
						<th v-for="(item, ind) in fields" :key="'L' + ind" class="px-7 py-5 border-b border-r border-gray-200 text-left text-sm font-semibold leading-4 text-gray-500 tracking-wider">
							<p>{{ item }}</p>
						</th>
					</tr>
				</thead>
				<tbody v-for="(item, ind) in data" :key="'A2' + ind" class="bg-white">
					<tr :class="{ 'bg-gray-30': item.medicalId === opened, 'cursor-pointer': item.hasBranch }" @click="openRow(item)">
						<td
							class="px-6 py-4 text-sm text-gray-500 text-left leading-5 whitespace-no-wrap border-b border-r border-gray-200"
							:class="{ 'underline text-dopBlue': item.medicalId === opened }"
						>
							<p :class="{ underline: item.hasBranch }">{{ item.name }}</p>
						</td>
						<td class="px-6 py-4 text-sm text-gray-500 text-left leading-5 whitespace-no-wrap border-b border-r border-gray-200">
							<p>{{ item.eachCount }}</p>
						</td>
						<td class="px-6 py-4 text-sm text-gray-500 text-left leading-5 whitespace-no-wrap border-b border-r border-gray-200">
							<p>{{ item._1_3 }}</p>
						</td>
						<td class="px-6 py-4 text-sm text-gray-500 text-left leading-5 whitespace-no-wrap border-b border-r border-gray-200">
							<p>{{ item._2_1 }}</p>
						</td>
						<td class="px-6 py-4 text-sm text-gray-500 text-left leading-5 whitespace-no-wrap border-b border-r border-gray-200">
							<p>{{ item._2_2 }}</p>
						</td>
						<td class="px-6 py-4 text-sm text-gray-500 text-left leading-5 whitespace-no-wrap border-b border-r border-gray-200">
							<p>{{ item._2_3 }}</p>
						</td>
						<td class="px-6 py-4 text-sm text-gray-500 text-left leading-5 whitespace-no-wrap border-b border-r border-gray-200">
							<p>{{ item._3_1 }}</p>
						</td>
						<td class="px-6 py-4 text-sm text-gray-500 text-left leading-5 whitespace-no-wrap border-b border-r border-gray-200">
							<p>{{ item._3_2 }}</p>
						</td>
						<td class="px-6 py-4 text-sm text-gray-500 text-left leading-5 whitespace-no-wrap border-b border-r border-gray-200">
							<p>{{ item._3_3 }}</p>
						</td>
						<td class="px-6 py-4 text-sm text-gray-500 text-left leading-5 whitespace-no-wrap border-b border-r border-gray-200">
							<p>{{ item._4_1 }}</p>
						</td>
						<td class="px-6 py-4 text-sm text-gray-500 text-left leading-5 whitespace-no-wrap border-b border-r border-gray-200">
							<p>{{ item._4_2 }}</p>
						</td>
						<td class="px-6 py-4 text-sm text-gray-500 text-left leading-5 whitespace-no-wrap border-b border-r border-gray-200">
							<p>{{ item._4_3 }}</p>
						</td>
						<td class="px-6 py-4 text-sm text-gray-500 text-left leading-5 whitespace-no-wrap border-b border-r border-gray-200">
							<p>{{ item._5_1 }}</p>
						</td>
						<td class="px-6 py-4 text-sm text-gray-500 text-left leading-5 whitespace-no-wrap border-b border-r border-gray-200">
							<p>{{ item._5_2 }}</p>
						</td>
						<td class="px-6 py-4 text-sm text-gray-500 text-left leading-5 whitespace-no-wrap border-b border-r border-gray-200">
							<p>{{ item._5_3 }}</p>
						</td>
					</tr>
					<tr v-if="opened === item.medicalId">
						<td colspan="17" class="bg-gray-20">
							<table class="w-full table-fixed">
								<tbody v-for="(subitem, indx) in item.branches" :key="'N' + indx">
									<tr>
										<td class="first-cell px-6 py-4 text-sm text-gray-500 text-left leading-5 whitespace-no-wrap border-b border-r border-gray-200">
											<div class="w-16 bg-textGray text-center text-xs text-white px-2 py-1 rounded mb-2.5">Филиал</div>
											<p>{{ subitem.name }}</p>
										</td>
										<td class="second-cell px-6 py-4 text-sm text-gray-500 text-left leading-5 whitespace-no-wrap border-b border-r border-gray-200">
											<p>{{ subitem.eachCount }}</p>
										</td>
										<td class="px-6 py-4 text-sm text-gray-500 text-left leading-5 whitespace-no-wrap border-b border-r border-gray-200">
											<p>{{ subitem.a_1_1 }}</p>
										</td>
										<td class="px-6 py-4 text-sm text-gray-500 text-left leading-5 whitespace-no-wrap border-b border-r border-gray-200">
											<p>{{ subitem.a_1_2 }}</p>
										</td>
										<td class="px-6 py-4 text-sm text-gray-500 text-left leading-5 whitespace-no-wrap border-b border-r border-gray-200">
											<p>{{ subitem.a_1_3 }}</p>
										</td>
										<td class="px-6 py-4 text-sm text-gray-500 text-left leading-5 whitespace-no-wrap border-b border-r border-gray-200">
											<p>{{ subitem.a_2_1 }}</p>
										</td>
										<td class="px-6 py-4 text-sm text-gray-500 text-left leading-5 whitespace-no-wrap border-b border-r border-gray-200">
											<p>{{ subitem.a_2_2 }}</p>
										</td>
										<td class="px-6 py-4 text-sm text-gray-500 text-left leading-5 whitespace-no-wrap border-b border-r border-gray-200">
											<p>{{ subitem.a_2_3 }}</p>
										</td>
										<td class="px-6 py-4 text-sm text-gray-500 text-left leading-5 whitespace-no-wrap border-b border-r border-gray-200">
											<p>{{ subitem.a_3_1 }}</p>
										</td>
										<td class="px-6 py-4 text-sm text-gray-500 text-left leading-5 whitespace-no-wrap border-b border-r border-gray-200">
											<p>{{ subitem.a_3_2 }}</p>
										</td>
										<td class="px-6 py-4 text-sm text-gray-500 text-left leading-5 whitespace-no-wrap border-b border-r border-gray-200">
											<p>{{ subitem.a_3_3 }}</p>
										</td>
										<td class="px-6 py-4 text-sm text-gray-500 text-left leading-5 whitespace-no-wrap border-b border-r border-gray-200">
											<p>{{ subitem.a_4_1 }}</p>
										</td>
										<td class="px-6 py-4 text-sm text-gray-500 text-left leading-5 whitespace-no-wrap border-b border-r border-gray-200">
											<p>{{ subitem.a_4_2 }}</p>
										</td>
										<td class="px-6 py-4 text-sm text-gray-500 text-left leading-5 whitespace-no-wrap border-b border-r border-gray-200">
											<p>{{ subitem.a_4_3 }}</p>
										</td>
										<td class="px-6 py-4 text-sm text-gray-500 text-left leading-5 whitespace-no-wrap border-b border-r border-gray-200">
											<p>{{ subitem.a_5_1 }}</p>
										</td>
										<td class="px-6 py-4 text-sm text-gray-500 text-left leading-5 whitespace-no-wrap border-b border-r border-gray-200">
											<p>{{ subitem.a_5_2 }}</p>
										</td>
										<td class="px-6 py-4 text-sm text-gray-500 text-left leading-5 whitespace-no-wrap border-b border-r border-gray-200">
											<p>{{ subitem.a_5_3 }}</p>
										</td>
									</tr>
								</tbody>
							</table>
						</td>
					</tr>
				</tbody>
			</table>
			<center v-if="!loadingTable && data.length === 0" class="w-full py-28 whitespace-no-wrap text-xl leading-5 text-gray-500 text-center">Данных нет...</center>
		</div>
		<!-- Навигация -->
		<div class="h-auto rounded-b-lg bg-white filter shadow-default">
			<Pagination :totalCount="totalCount" :params="params" @query="getData" />
		</div>
		<!-- Прелоудер -->
		<div v-if="loadingTable" class="absolute w-full h-full preloader rounded-lg">
			<loadingComponent class="absolute top-1/2 left-1/2" />
		</div>
	</div>
</template>

<script>
// Глобальные данные стора
import { getters, methods, actions } from '@/store/store';
import { mutations } from '../store/mutations';
// Локальные данные стора
import { gettersLocal } from '../store/getters';
import action from '../../NOCCurrentYear/store/actions';
import loadingComponent from '@/components/Loading.vue';
import Pagination from '@/components/Pagination.vue';
import FilterComponent from '@/components/Filter.vue';
import Button from '@/components/Button.vue';

export default {
	name: 'TableResults',
	components: {
		loadingComponent,
		Pagination,
		FilterComponent,
		Button
	},
	computed: {
		...getters,
		...gettersLocal
	},
	data() {
		return {
			selectTerm: null,
			selectedType: null,
			medArr: [
				{ value: 'isAmbulator', label: 'Амбулаторные' },
				{ value: 'isStacionar', label: 'Стационарные' },
				{ value: 'isSanatoriat', label: 'Санаторно-курортные' },
				{ value: 'isPsychiatric', label: 'Психиатрические больницы' }
			],
			anketType: [
				{ value: '3', label: 'Все' },
				{ value: '2', label: 'Интерактивные' },
				{ value: '1', label: 'Бумажные' }
			],
			loadingTable: false,
			loadToExcell: false,
			circumference: 30 * 2 * Math.PI,
			rowOpened: {
				key: null,
				type: null
			},
			openContentChange: false,
			rowsVisible: 10,
			totalCount: 0,
			filterOpen: null,
			filterBy: null,
			opened: null,
			fields: ['1.3', '2.1', '2.2', '2.3', '3.1', '3.2', '3.3', '4.1', '4.2', '4.3', '5.1', '5.2', '5.3'],
			subFields: [
				'Открытость и доступность информации об организации',
				'Комфортность условий предоставления услуг, включая время ожидания предоставления медицинской услуги',
				'Доступность услуг для инвалидов',
				'Доброжелательность, вежливость работников медицинской организации',
				'Удовлетворенность условиями оказания услуг'
			],
			fields_sticky: [
				{
					Name: 'Наименование МО',
					fieldName: 'Name',
					filterType: 2,
					filterValueType: 1,
					value1: '',
					value2: null,
					sortBy: null,
					paramsFiter: '',
					label1: 'По возрастанию (А-Я)',
					label2: 'По убыванию (Я-А)'
				},
				{
					Name: 'Количество анкет',
					fieldName: 'TotalCount',
					filterType: 1,
					filterValueType: 2,
					value1: '',
					value2: null,
					sortBy: null,
					paramsFiter: '[0-9.]',
					label1: 'По возрастанию',
					label2: 'По убыванию'
				}
			],
			data: [],
			openName: false,
			openChange: false,
			openFIO: true,
			filterData: {
				sortBy: '',
				searchValue: ''
			},
			openAllChange: false,
			users: [],
			params: {
				filters: [
					{
						fieldName: 'RegionId',
						filterType: 1,
						filterValueType: 1,
						value1: ''
					},
					{
						fieldName: 'isAmbulator',
						filterType: 1,
						filterValueType: 1,
						value1: 'true'
					},
					{
						fieldName: 'VoteType',
						filterType: 1,
						filterValueType: 1,
						value1: '3'
					},
					{
						fieldName: 'isMain',
						filterType: 1,
						filterValueType: 1,
						value1: 'true',
						value2: ''
					}
				],
				sortOption: {
					fieldName: '',
					sortType: null
				},
				pageSize: 5,
				currentPage: 0,
				columns: []
			}
		};
	},
	methods: {
		...action,
		...methods,
		...mutations,
		getRegionYearData: actions.getRegionYearData,
		openRow(item) {
			if (item.hasBranch) {
				if (this.opened === item.medicalId) {
					this.opened = null;
				} else {
					this.opened = item.medicalId;
				}
			}
		},
		switchFilterVisibility(key) {
			this.filterOpen = this.filterOpen === key ? null : key;
			this.filterBy = key;
		},
		getData(params, flag) {
			this.loadingTable = true;
			// если фильтруем/сортируем то возвращаем пагинацию на 1 страницу
			if (flag) {
				this.params.currentPage = 0;
			}

			this.params.filters.push({
				fieldName: 'YearStr',
				filterType: 2,
				filterValueType: 1,
				value1: this.getYear
			});
			if (this.params.sortOption.sortType) {
				let formTypeId = '';
				if (this.params.filters[1].fieldName === 'isAmbulator') formTypeId = 'AmbulatorRes';
				if (this.params.filters[1].fieldName === 'isStacionar') formTypeId = 'StacionarRes';
				if (this.params.filters[1].fieldName === 'isSanatoriat') formTypeId = 'SanatoriatRes';
				if (this.params.filters[1].fieldName === 'isPsychiatric') formTypeId = 'PsychaitricRes';
			}

			for (let i = 0; i < this.fields_sticky.length; i += 1) {
				let Name = this.fields_sticky[i].Name,
					PFN = this.params.filters[1].fieldName;

				// console.log(Name, PFN, this.fields_sticky[i]);
				if (Name === 'Количество анкет' && PFN === 'isAmbulator') this.fields_sticky[i].fieldName = 'ambulatorcount';
				if (Name === 'Количество анкет' && PFN === 'isStacionar') this.fields_sticky[i].fieldName = 'StacionarCount';
				if (Name === 'Количество анкет' && PFN === 'isSanatoriat') this.fields_sticky[i].fieldName = 'sanatoriatcount';
				if (Name === 'Количество анкет' && PFN === 'isPsychiatric') this.fields_sticky[i].fieldName = 'PsychiatricCount';
			}

			this.getMOAnketResults(this.params).then((res) => {
				this.data = res.data.data;
				this.totalCount = Math.ceil(res.data.totalRecords / this.params.pageSize);
				this.loadingTable = false;
			});
			this.params.filters.pop();
		},
		downloadExcell(params) {
			this.loadToExcell = true;
			this.params.filters.push({
				fieldName: 'YearStr',
				filterType: 2,
				filterValueType: 1,
				value1: this.getYear
			});
			this.$http
				.post('MedicalOrgs/GetMOAnketResultsExcel', params, {
					responseType: 'blob',
					headers: {
						'Access-Control-Expose-Headers': 'Content-Disposition'
					}
				})
				.then((res) => {
					let fileNameAndType = decodeURI(`${this.regionName}_Результаты анкетирования.xlsx`);

					const url = window.URL.createObjectURL(new Blob([res.data]));
					const link = document.createElement('a');
					link.href = url;
					link.setAttribute('download', fileNameAndType);
					document.body.appendChild(link);
					link.click();
					document.body.removeChild(link);
					this.loadToExcell = false;
				});
			params.filters.pop();
		},
		upLoadSaveExcel(params) {
			this.loadToExcell = true;
			let saveParam = {};
			params.filters.forEach((item) => {
				if (item.fieldName === 'RegionId') saveParam[item.fieldName] = item.value1;
				if (item.fieldName === 'YearStr') saveParam[item.fieldName] = item.value1;
				if (item.fieldName === 'VoteType') saveParam[item.fieldName] = item.value1;
				if (item.fieldName === 'isAmbulator') {
					saveParam.formTypeId = '111';
					saveParam.name = 'Амбулаторные';
				}
				if (item.fieldName === 'isStacionar') {
					saveParam.formTypeId = '115';
					saveParam.name = 'Стационарные';
				}
				if (item.fieldName === 'isSanatoriat') {
					saveParam.formTypeId = '114';
					saveParam.name = 'Санаторно-курортные';
				}
				if (item.fieldName === 'isPsychiatric') {
					saveParam.formTypeId = '113';
					saveParam.name = 'Психиатрические больницы';
				}
			});
			this.$http
				.get(`Anketa/GetAnketaReportExcel?RegionId=${saveParam.RegionId}&FormTypeId=${saveParam.formTypeId}&paperTypeId=${saveParam.VoteType}&year=${this.getYear}`, {
					responseType: 'blob',
					headers: {
						'Access-Control-Expose-Headers': 'Content-Disposition'
					}
				})
				.then((res) => {
					let fileNameAndType = decodeURI(`${this.regionName}_${saveParam.name}_${this.getYear}_.xlsx`);

					const url = window.URL.createObjectURL(new Blob([res.data]));
					const link = document.createElement('a');
					link.href = url;
					link.setAttribute('download', fileNameAndType);
					document.body.appendChild(link);
					link.click();
					document.body.removeChild(link);
					this.loadToExcell = false;
				});
		}
	},
	mounted() {
		let regionId = this.getRegionId();
		this.params.filters[0].value1 = regionId;

		if (this.getYear) {
			this.getData(null, false);
		} else {
			this.getRegionYearData(regionId).then((res) => {
				this.setYear(res.data.previousYear);

				this.getData(null, false);
			});
		}
	}
};
</script>

<style scoped>
.first-cell {
	width: 417px;
}

.second-cell {
	width: 187px;
}

.third-cell {
	width: 3028px;
}

.t-overflow {
	overflow: auto;
	min-height: 300px;
	max-width: 100vw;
}

.t-overflow::-webkit-scrollbar {
	width: 11px; /* ширина всей полосы прокрутки */
}

.t-overflow::-webkit-scrollbar-track {
	background: rgba(168, 177, 206, 0.3); /* цвет зоны отслеживания */
}

.t-overflow::-webkit-scrollbar-thumb {
	background: #3377ff;
	border-radius: 10px;
}
</style>
