<template>
	<div class="flex flex-col relative">
		<div class="absolute right-0 -top-14">
			<Button text="Экспорт в Excel" icon="exel" @click.native="downloadExcell(params)" :disabled="loadToExcell" :load="loadToExcell" />
		</div>
		<div class="t-overflow rounded-tr-lg overflow-x-auto h-auto bg-white filter shadow-default">
			<table class="table-fixed w-full">
				<thead>
					<tr>
						<th rowspan="2" class="first-cell px-6 py-4 border-b border-r border-gray-200 text-left text-sm leading-4 font-semibold text-gray-500 tracking-wider">
							<span class="relative">
								<span class="border-b border-gray-200 cursor-pointer" :class="{ 'text-blue-500 underline': filterBy === 0 }" @click="switchFilterVisibility(0)"> Наименование МО </span>
								<!-- Фильтр и сортировка -->
								<FilterComponent
									v-on:change="switchFilterVisibility(null)"
									position="left"
									:filterOpen="filterOpen"
									:id-filter="0"
									:param="params"
									@query="getData"
									:fields="fields"
									:item="fields[0]"
								/>
							</span>
						</th>
						<th colspan="5" class="second-cell px-6 py-4 border-b border-gray-200 text-center text-sm leading-4 font-semibold text-gray-500 tracking-wider">
							<p>Количество заполненных анкет</p>
						</th>
					</tr>
					<tr>
						<th
							v-for="(item, ind) in fields.slice(1)"
							:key="'J' + ind"
							class="px-6 py-4 border-b border-r border-gray-200 text-center text-sm leading-4 font-semibold text-gray-500 tracking-wider"
						>
							<span class="relative">
								<p>{{ item.Name }}</p>
							</span>
						</th>
					</tr>
				</thead>
				<tbody v-for="(item, indx) in data" :key="'A' + indx">
					<tr :class="{ 'bg-gray-30': item.medicalorgid === opened, 'cursor-pointer': item.hasbranch }" @click="openRow(item)">
						<td
							v-for="(field, ind) in fields"
							:key="'L' + ind"
							class="px-6 py-4 font-normal text-sm whitespace-no-wrap border-b border-gray-200 leading-5 text-gray-500"
							:class="{ 'border-none': item.medicalorgid === opened, 'text-left': ind === 0, 'text-center': ind !== 0 }"
						>
							<p :class="{ underline: item.hasbranch }">
								{{ item[field.fieldName] }}
							</p>
						</td>
					</tr>
					<tr v-if="opened === item.medicalorgid">
						<td colspan="6" class="bg-gray-20">
							<table class="w-full table-fixed">
								<tbody v-for="(subitem, indx) in item.branches" :key="'N' + indx">
									<tr>
										<td
											v-for="(field, ind) in fields"
											:key="'K' + ind"
											class="px-6 py-3 font-normal text-sm whitespace-no-wrap border-b border-gray-200 leading-5 text-gray-500"
											:class="{ 'text-center': ind !== 0, 'first-cell text-left': ind === 0 }"
										>
											<div v-if="ind === 0" class="w-16 bg-textGray text-center text-xs text-white px-2 py-1 rounded mb-2.5">Филиал</div>
											<p>
												{{ subitem[field.fieldName] }}
											</p>
										</td>
									</tr>
								</tbody>
							</table>
						</td>
					</tr>
				</tbody>
			</table>
			<center v-if="!loadingTable && data.length === 0" class="w-full py-28 whitespace-no-wrap text-xl leading-5 text-gray-500 text-center">Данных нет...</center>
		</div>
		<!-- Навигация -->
		<div class="h-auto rounded-b-lg bg-white filter shadow-default">
			<Pagination :totalCount="totalCount" :params="params" @query="getData" />
		</div>
		<!-- Прелоудер -->
		<div v-if="loadingTable" class="absolute w-full h-full preloader rounded-lg">
			<loadingComponent class="absolute top-1/2 left-1/2" />
		</div>
	</div>
</template>

<script>
// Глобальные данные стора
import { getters, methods, actions } from '@/store/store';
// Локальные данные стора
import { gettersLocal } from '../store/getters';
import { mutations } from '../store/mutations';
import action from '../../NOCCurrentYear/store/actions';
import loadingComponent from '@/components/Loading.vue';
import Pagination from '@/components/Pagination.vue';
import FilterComponent from '@/components/Filter.vue';
import Button from '@/components/Button.vue';

export default {
	name: 'TableMO',
	props: {
		regionId: String
	},
	components: {
		loadingComponent,
		Pagination,
		FilterComponent,
		Button
	},
	computed: {
		...gettersLocal,
		...getters
	},
	data() {
		return {
			loadingTable: false,
			loadToExcell: false,
			circumference: 30 * 2 * Math.PI,
			rowsVisible: 10,
			totalCount: 0,
			filterOpen: null,
			filterBy: null,
			opened: null,
			fields: [
				{
					Name: 'Наименование МО',
					fieldName: 'name',
					filterType: 2,
					filterValueType: 1,
					value1: '',
					value2: null,
					sortBy: null,
					label1: 'По возрастанию (А-Я)',
					label2: 'По убыванию (Я-А)'
				},
				{
					Name: 'Общее количество',
					fieldName: 'allcount',
					filterType: 1,
					filterValueType: 2,
					value1: '',
					value2: null,
					sortBy: null,
					label1: 'По возрастанию',
					label2: 'По убыванию'
				},
				{
					Name: 'Амбулаторные',
					fieldName: 'ambulatorcount',
					filterType: 1,
					filterValueType: 2,
					value1: '',
					value2: null,
					sortBy: null,
					label1: 'По возрастанию',
					label2: 'По убыванию'
				},
				{
					Name: 'Стационарные',
					fieldName: 'stacionarcount',
					filterType: 1,
					filterValueType: 2,
					value1: '',
					value2: null,
					sortBy: null,
					label1: 'По возрастанию',
					label2: 'По убыванию'
				},
				{
					Name: 'Санаторно-курортные',
					fieldName: 'sanatoriacount',
					filterType: 1,
					filterValueType: 2,
					value1: '',
					value2: null,
					sortBy: null,
					label1: 'По возрастанию',
					label2: 'По убыванию'
				},
				{
					Name: 'Психиатрические',
					fieldName: 'psychiatriccount',
					filterType: 2,
					filterValueType: 1,
					value1: '',
					value2: null,
					sortBy: null,
					label1: 'По возрастанию',
					label2: 'По убыванию'
				}
			],
			data: [],
			params: {
				filters: [
					{
						fieldName: 'RegionId',
						filterType: 1,
						filterValueType: 1,
						value1: ''
					},
					{
						fieldName: 'YearStr',
						filterType: 2,
						filterValueType: 2,
						value1: '2022'
					},
					{
						fieldName: 'isMain',
						filterType: 1,
						filterValueType: 1,
						value1: 'true',
						value2: ''
					}
				],
				sortOption: {
					fieldName: '',
					sortType: null
				},
				columns: [],
				pageSize: 5,
				currentPage: 0
			}
		};
	},
	methods: {
		...action,
		...methods,
		...mutations,
		getRegionYearData: actions.getRegionYearData,
		openRow(item) {
			if (item.hasbranch) {
				if (this.opened === item.medicalorgid) {
					this.opened = null;
				} else {
					this.opened = item.medicalorgid;
				}
			}
		},
		switchFilterVisibility(key) {
			this.filterOpen = this.filterOpen === key ? null : key;
			this.filterBy = key;
		},
		getData(params, flag) {
			// если фильтруем/сортируем то возвращаем пагинацию на 1 страницу
			if (flag) {
				this.params.currentPage = 0;
			}

			this.params.filters[1].value1 = this.getYear;
			this.loadingTable = true;
			this.getIncludedMO(this.params).then((res) => {
				this.data = res.data.data;
				this.totalCount = Math.ceil(res.data.totalRecords / this.params.pageSize);
				this.loadingTable = false;
			});
		},
		downloadExcell(params) {
			this.params.filters[1].value1 = this.getYear;
			this.loadToExcell = true;
			this.$http
				.post('MedicalOrgs/GetIncludedMOExcel', params, {
					responseType: 'blob',
					headers: {
						'Access-Control-Expose-Headers': 'Content-Disposition'
					}
				})
				.then((res) => {
					let fileNameAndType = decodeURI(`${this.regionName}_Перечень медицинских организаций текущего года.xlsx`);

					const url = window.URL.createObjectURL(new Blob([res.data]));
					const link = document.createElement('a');
					link.href = url;
					link.setAttribute('download', fileNameAndType);
					document.body.appendChild(link);
					link.click();
					document.body.removeChild(link);
					this.loadToExcell = false;
				});
		}
	},
	created() {
		if (this.userIsInRoles(['Support', 'MZ_Employee', 'MZ_Observer'])) {
			this.params.filters[0].value1 = this.$route.query.regionId;
		} else {
			this.params.filters[0].value1 = this.getAuthUser.regionId;
		}

		if (this.getYear) {
			this.getData(null, false);
		} else {
			this.getRegionYearData(this.regionId).then((res) => {
				this.setYear(res.data.previousYear);
				this.getData(null, false);
			});
		}
	}
};
</script>

<style scoped>
.first-cell {
	width: 320px;
}

.t-overflow {
	overflow: auto;
	max-width: 100vw;
	min-height: 300px;
}

.t-overflow::-webkit-scrollbar {
	width: 11px; /* ширина всей полосы прокрутки */
}

.t-overflow::-webkit-scrollbar-track {
	background: rgba(168, 177, 206, 0.3); /* цвет зоны отслеживания */
}

.t-overflow::-webkit-scrollbar-thumb {
	background: #3377ff;
	border-radius: 10px;
}
</style>
